import { set } from "date-fns";
import { supplementaryCrud } from "../../../crudRequests";

export async function deleteSymptom(setSymptoms, symptomToDelete, did, toast) {
  try {
    let { _id, ...updatedSymptomContent } = symptomToDelete;
    updatedSymptomContent.associatedDisorders = updatedSymptomContent.associatedDisorders.filter((disorderId) => disorderId !== did);
    if (updatedSymptomContent.associatedDisorders.length > 0) {
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "symptoms",
          parameters: [{ sid: symptomToDelete.sid }, { $set: updatedSymptomContent }],
          method: "updateOne",
        },
      ]);
      updatedSymptomContent._id = _id;
      setSymptoms((prev) => {
        return prev.map((symptom) => {
          if (symptom.sid === updatedSymptomContent.sid) {
            return updatedSymptomContent;
          } else {
            return symptom;
          }
        });
      });
    } else {
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "symptoms",
          parameters: [{ sid: symptomToDelete.sid }],
          method: "deleteOne",
        },
      ]);
      setSymptoms((prev) => {
        return prev.filter((symptom) => symptom.sid !== symptomToDelete.sid);
      });
    }
    toast({
      title: "Successfully deleted symptom",
      description: symptomToDelete.name + " and it's metadata has been deleted.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Failed to delete symptom",
      description: err?.message || "An error occurred while deleting symptom.",
      status: "error",
      isClosable: true,
    });
  }
}
