import React, { useState, useRef, useEffect } from "react";
import { crud } from "../crudRequests";
import { generateQuestion, generateMeaning } from "../intakeRequests";
import { AiOutlinePartition } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

export default function AddQuestionModal(props) {
  const state = props.state;
  const stateType = props.stateType;
  const allSymptoms = props.allSymptoms;
  const allQuestions = props.allQuestions;
  const allDisorderQuestions = props.allDisorderQuestions;
  const selectedSymptom = props.selectedSymptom;
  const stateChanger = props.stateChanger;
  const questionToEdit = props.questionToEdit;

  let pastQuestionAndAnswer =
    stateType == "symptom_questions" && questionToEdit.pathways.includes("*")
      ? allQuestions.find((q) => q.code == questionToEdit.pathways.split("*").slice(-2)[0].split("{").slice(-1)[0]).question +
        "|" +
        questionToEdit.pathways.split("*").slice(-1)[0].split("}")[0]
      : "";

  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [loadingMeaning, setLoadingMeaning] = useState(false);

  const [newQuestionText, setNewQuestionText] = useState(questionToEdit.question);
  const [newQuestionMeaning, setNewQuestionMeaning] = useState(questionToEdit.meaning);

  const [durationresp, setDurationresp] = useState(questionToEdit.duration);
  const [mandresp, setMandresp] = useState(questionToEdit.mandatory);

  function edit() {
    let updateBody =
      stateType == "symptom_questions"
        ? {
            id: questionToEdit.id,
            question: newQuestionText,
            meaning: newQuestionMeaning ? newQuestionMeaning : "",
            duration: durationresp,
            mandatory: mandresp,
          }
        : {
            id: questionToEdit.id,
            question: newQuestionText,
            meaning: newQuestionMeaning ? newQuestionMeaning : "",
          };

    crud(state, [
      {
        db: "intake",
        collection: stateType,
        parameters: [{ id: updateBody.id }, { $set: updateBody }],
        method: "updateOne",
      },
    ]).then(() => stateChanger(false));
  }

  return (
    <>
      <div
        className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{ backgroundColor: "rgb(0,0,0,0.5)" }}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6 sm:align-middle"
            style={{ backgroundColor: "#F6F5FF" }}
          >
            <div className="flex -mb-7">
              <h3 className="text-3xl font-medium text-gray-800 m-4 mb-8" id="modal-title" style={{ color: "#C0BFFF" }}>
                edit question
              </h3>
              <div className="float-right ml-36 underline text-med cursor-pointer" onClick={() => stateChanger(false)}>
                x
              </div>
            </div>
            <form className="mt-6 mx-5" action="#">
              <div className={"mt-0.5 font-regular text-sm rounded-md bg-dark border-none text-background w-[22.5rem]"}>
                <span
                  onClick={() =>
                    generateQuestion(
                      state,
                      allSymptoms.find((s) => s.symId === selectedSymptom).shortDesc,
                      pastQuestionAndAnswer,
                      stateType,
                      setNewQuestionText,
                      setLoadingQuestion
                    )
                  }
                  className="pl-1.5 inline-block text-lg"
                >
                  {loadingQuestion ? (
                    <FaSpinner className="-mt-1 animate-spin text-white inline cursor-pointer mx-0.5" size={16} />
                  ) : (
                    <AiOutlinePartition className="-mt-1 text-white inline cursor-pointer" size={20} />
                  )}
                </span>{" "}
                <textarea
                  className="text-sm mb-[-30px] p-[0.33rem] pl-3 ml-[0.125rem] rounded-md w-[20.5rem] text-off"
                  placeholder="create question..."
                  value={newQuestionText}
                  onChange={(e) => setNewQuestionText(e.target.value)}
                ></textarea>
              </div>
              <div className={"mt-8 font-regular text-sm rounded-md bg-med border-none text-background w-[22.5rem]"}>
                <span onClick={() => generateMeaning(state, newQuestionText, setNewQuestionMeaning, setLoadingMeaning)} className="pl-1.5 text-lg">
                  {loadingMeaning ? (
                    <FaSpinner className="-mt-1 animate-spin text-white inline cursor-pointer mx-0.5" size={16} />
                  ) : (
                    <AiOutlinePartition className="-mt-1 text-white inline cursor-pointer" size={20} />
                  )}
                </span>{" "}
                <textarea
                  className="p-[0.33rem] mb-[-70px] pl-3 ml-[0.125rem] rounded-md w-[20.5rem] text-sm text-off"
                  placeholder="add meaning..."
                  rows={4}
                  value={newQuestionMeaning}
                  onChange={(e) => setNewQuestionMeaning(e.target.value)}
                ></textarea>
              </div>
              {stateType == "symptom_questions" && (
                <div className="mt-[5.5rem]">
                  <div className="text-md rounded-md text-background ">
                    <div className="flex">
                      <span className="tooltip">
                        <div
                          className={
                            durationresp == true
                              ? "flex bg-dark p-3 rounded-md text-sm cursor-pointer mb-[0.33rem] "
                              : "flex bg-med bg-opacity-50 p-3 rounded-md text-sm cursor-pointer mb-[0.33rem] "
                          }
                          onClick={() => {
                            setDurationresp(!durationresp);
                          }}
                        >
                          <img src="/images/App/calendar.svg" className="h-[35px] mt-1.5 ml-2.5" />
                          <div className="mt-1 mx-4 my-0.5">
                            duration
                            <br />
                            question
                          </div>
                        </div>
                        <span className="tooltiptext">Question that is requiring a standard duration question next</span>
                      </span>
                      <span className="tooltip">
                        <div
                          className={
                            mandresp == true
                              ? "flex bg-dark p-3 rounded-md text-sm ml-6 pl-5 pr-5 cursor-pointer mb-[0.33rem]"
                              : "flex bg-med bg-opacity-50 p-3 rounded-md text-sm ml-6 pl-5 pr-5 cursor-pointer mb-[0.33rem]"
                          }
                          onClick={() => {
                            setMandresp(!mandresp);
                          }}
                        >
                          <img src="/images/App/mandatory.png" className="h-8 mt-1.5 mb-2 ml-1.5" />
                          <div className="mt-1 ml-4 mr-2">
                            mandatory
                            <br />
                            question
                          </div>
                        </div>
                        <span className="tooltiptext">Question that must be asked given such context</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${
                  stateType == "disorder_questions" ? `mt-20` : `mt-6`
                } w-[22.5rem] mb-4 rounded-lg text-background bg-gradient-to-r from-dark to-pink-500`}
              >
                <button type="button" className="w-full py-2 rounded-lg font-medium" onClick={edit}>
                  <img src={"/images/App/create.svg"} className="h-5 -mt-1.5 inline-block" /> update question
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
