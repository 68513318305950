import reportWebVitals from "./additional_files/reportWebVitals";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Configuration from "./pages/Configuration";
import Chains from "./pages/Chains";
import Symptoms from "./pages/Symptoms";
import Design from "./pages/Design";
import { ChakraProvider } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ChakraProvider>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/" element={<Dashboard />} />
        <Route path="/chains" element={<Chains />}></Route>
        <Route path="/configuration" element={<Configuration />}></Route>
        <Route path="/symptoms" element={<Symptoms />}></Route>
        <Route path="/design" element={<Design />}></Route>
      </Routes>
    </ChakraProvider>
  </BrowserRouter>
);

reportWebVitals();
