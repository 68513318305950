import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { deleteSymptom } from "./helpers/deleteSymptom";
import { DeleteIcon } from "@chakra-ui/icons";

export default function DeleteSymptom({ setSymptoms, symptom, did }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  return (
    <>
      <IconButton aria-label="Confirm delete" m="1" variant="outline" icon={<DeleteIcon />} size="sm" onClick={onOpen} />
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Symptom
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this symptom, and all of its metadata? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  deleteSymptom(setSymptoms, symptom, did, toast);
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
