import { supplementaryCrud } from "../../../crudRequests";
import { nanoid } from "nanoid";
import { fetchDurationQuestion } from "./fetchDurationQuestion";

export async function createUpdateDurationQuestion(e, durationQuestion, setDurationQuestion, sid, userId, setIsLoading, toast) {
  try {
    setIsLoading(true);
    e.preventDefault();

    if (durationQuestion?._id) {
      // If the duration question already exists, update it
      let { _id, ...updatedQuestionContent } = durationQuestion;
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "duration_questions",
          parameters: [{ dqid: updatedQuestionContent.dqid }, { $set: updatedQuestionContent }],
          method: "updateOne",
        },
      ]);
      toast({
        title: "Successfully updated duration question",
        description: "Your duration question has been successfully updated for this symptom.",
        status: "success",
        isClosable: true,
      });
      setIsLoading(false);
    } else {
      // If the duration question does not exist, create it
      durationQuestion.dqid = nanoid();
      durationQuestion.sid = sid;
      durationQuestion.dateCreated = new Date();
      durationQuestion.associatedUsers = [userId];
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "duration_questions",
          parameters: [durationQuestion],
          method: "insertOne",
        },
      ]);
      toast({
        title: "Successfully created duration question",
        description: "A new duration question has been added for this symptom.",
        status: "success",
        isClosable: true,
      });
      fetchDurationQuestion(setDurationQuestion, sid, userId, toast);
      setIsLoading(false);
    }
  } catch (err) {
    toast({
      title: "Failed to create/update duration question",
      description: err?.message || "An error occurred while creating/updating duration question.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
