import { render } from "@testing-library/react";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export default function Dashboard(props) {
  return (
    <>
      <div id="no_phone" className="hidden text-med m-7 bg-light bg-opacity-50 p-7 rounded-md font-medium text-xl w-screen">
        Please make sure to access the intake design app on a larger screen such as a laptop or desktop for the best experience.
      </div>
      <div className="w-screen h-screen flex">
        <div
          className="p-8 pt-16 h-screen min-w-[25rem] w-1/3 bg-light bg-opacity-10 cursor-pointer"
          onClick={() => (window.location.href = "/symptoms")}
        >
          <img src="/images/App/symptoms.png" className="h-24 m-auto opacity-30" />
          <div className="mt-24 mb-24 p-4 text-[65px] leading-[5rem] font-semibold text-center">
            <span className="text-dark text-opacity-40">Symptom</span>
            <br />
            <span className="text-dark text-opacity-70">Creation</span>
            <br />
            <span className="text-dark">App</span>
          </div>
        </div>

        <div
          className="p-8 pt-16 h-screen min-w-[25rem] w-1/3 bg-light bg-opacity-30 cursor-pointer"
          onClick={() => (window.location.href = "/configuration")}
        >
          <img src="/images/App/chains.png" className="h-24 m-auto opacity-70" />
          <div className="mt-24 mb-24 p-4 text-[65px] leading-[5rem] font-semibold text-center">
            <span className="text-dark text-opacity-40">Intake</span>
            <br />
            <span className="text-dark text-opacity-70">Design</span>
            <br />
            <span className="text-dark">App</span>
          </div>
        </div>

        <div
          className="p-8 pt-16 h-screen min-w-[25rem] w-1/3 bg-light bg-opacity-50 cursor-pointer"
          onClick={() => (window.location.href = "/general")}
        >
          <img src="/images/App/training.png" className="h-24 m-auto" />
          <div className="mt-24 mb-24 p-4 text-[65px] leading-[5rem] font-semibold text-center">
            <span className="text-dark text-opacity-40">Chatbot</span>
            <br />
            <span className="text-dark text-opacity-70">Training</span>
            <br />
            <span className="text-dark">App</span>
          </div>
        </div>
      </div>
    </>
  );
}
