import { supplementaryCrud } from "../../../crudRequests";
import { nanoid } from "nanoid";
import { fetchFollowupQuestions } from "./fetchFollowupQuestions";

export async function createUpdateFollowupQuestion(
  e,
  followupQuestion,
  setFollowupQuestions,
  setFollowupQuestion,
  sid,
  multipleChoice,
  userId,
  setIsLoading,
  toast
) {
  try {
    setIsLoading(true);
    e.preventDefault();

    if (followupQuestion?._id) {
      // If the duration question already exists, update it
      let { _id, ...updatedQuestionContent } = followupQuestion;
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "followup_questions",
          parameters: [{ fqid: updatedQuestionContent.fqid }, { $set: updatedQuestionContent }],
          method: "updateOne",
        },
      ]);
      toast({
        title: "Successfully updated followup question",
        description: "Your followup question has been successfully updated for this symptom.",
        status: "success",
        isClosable: true,
      });
      fetchFollowupQuestions(setFollowupQuestions, sid, userId, toast);
      setFollowupQuestion({});
      setIsLoading(false);
    } else {
      // If the duration question does not exist, create it

      followupQuestion.fqid = nanoid();
      followupQuestion.sid = sid;
      followupQuestion.multipleChoice = multipleChoice ? true : false;
      followupQuestion.dateCreated = new Date();
      followupQuestion.associatedUsers = [userId];

      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "followup_questions",
          parameters: [followupQuestion],
          method: "insertOne",
        },
      ]);
      toast({
        title: "Successfully created followup question",
        description: "A new followup question has been added for this symptom.",
        status: "success",
        isClosable: true,
      });
      fetchFollowupQuestions(setFollowupQuestions, sid, userId, toast);
      setFollowupQuestion({});
      setIsLoading(false);
    }
  } catch (err) {
    toast({
      title: "Failed to create followup question",
      description: err?.message || "An error occurred while creating followup question.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
