import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { deleteQuestion } from "./helpers/deleteQuestion";
import { DeleteIcon } from "@chakra-ui/icons";

export default function DeleteQuestion({ setQuestions, question, setQuestion, type }) {
  const toast = useToast();

  return (
    <>
      <AlertDialog isOpen={question} onClose={() => setQuestion(null)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Question
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this question, and all of its metadata? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setQuestion(null)}>Cancel</Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  deleteQuestion(setQuestions, question, type, toast);
                  setQuestion(null);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
