import axios from "axios";
const url = window.location.href.includes("localhost")
  ? "http://localhost:3000"
  : "https://webserver.brightlight.ai";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export async function loginUser(email, password, userType, db) {
  let config = {
    method: "POST",
    url: `${url}/loginUser`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      email: email,
      password: password,
      userType: userType,
      db,
    }),
  };
  return await axios(config);
}

export async function verifyUser(jwt, fadeInContent) {
  let config = {
    method: "POST",
    url: `${url}/verifyUser`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({ id: jwt ? jwtDecode(jwt)?.id : "" }),
  };
  axios(config)
    .then(() => {
      var fc = 0;
      fadeInContent?.current &&
        (function fade() {
          if (fadeInContent?.current) {
            fadeInContent.current.style.opacity = fc = fc + 0.1;
            fadeInContent.current.style.opacity != 1 && setTimeout(fade, 30);
          }
        })();
      return;
    })
    .catch((e) => {
      console.log(e);
      window.location.href = "../login";
    });
}

export async function verifyLoginCode({ jwt, code, userType, user, db }) {
  let config = {
    method: "POST",
    url: `${url}/verifyLoginCode`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({ id: jwtDecode(jwt).id, code, userType, user, db }),
  };
  return axios(config);
}

export function setAuthCode({ jwt, userType, user, db }) {
  let config = {
    method: "POST",
    url: `${url}/setAuthCode`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt,
    },
    data: JSON.stringify({
      id: jwtDecode(jwt).id,
      userType,
      user,
      db,
    }),
  };
  return axios(config);
}

export function logoutUser() {
  window.location.href = "../login";
}
