import { nanoid } from "nanoid";
import { supplementaryCrud } from "../../../crudRequests";

export async function createSymptom(setSymptoms, name, description, metadata, priority, did, userId, toast) {
  try {
    let payload = {
      sid: nanoid(),
      name,
      description,
      metadata,
      priority,
      riskLevel: "1",
      dateCreated: new Date(),
      associatedUsers: [userId],
      associatedDisorders: [did],
    };
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "symptoms",
        parameters: [payload],
        method: "insertOne",
      },
    ]);
    setSymptoms((prevData) => [...prevData, payload]);
    toast({
      title: "Successfully created symptom",
      description: name + " has been added for configuration.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Failed to create symptom",
      description: err?.message || "An error occurred while creating symptom.",
      status: "error",
      isClosable: true,
    });
  }
}
