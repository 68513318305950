import { DashStateContext } from "../../pages/Design";
import { Text, Box, Icon, Grid, Checkbox, Button, FormControl, FormLabel, Input, Alert, AlertIcon, Flex, useToast } from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { fetchQualifierQuestion } from "./helpers/fetchQualifierQuestion";
import { createUpdateQualifierQuestion } from "./helpers/createUpdateQualifierQuestion";
import { autogenerateQualifierQuestion } from "./helpers/autogenerateQualifierQuestion";
import { FaMagic } from "react-icons/fa";
import DeleteQuestion from "./DeleteQuestion";

export default function Qualifier({ symptom }) {
  const toast = useToast();
  const { userId } = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutogenerating, setIsAutogenerating] = useState(false);
  const [qualifierQuestion, setQualifierQuestion] = useState();
  const [questionToDelete, setQuestionToDelete] = useState(false);

  useEffect(() => {
    symptom && fetchQualifierQuestion(setQualifierQuestion, symptom.sid, userId, toast);
  }, [symptom]);

  return (
    <form onSubmit={(e) => createUpdateQualifierQuestion(e, qualifierQuestion, setQualifierQuestion, symptom.sid, userId, setIsLoading, toast)}>
      <Box textAlign={"center"}>
        <Alert colorScheme="blue" variant="left-accent" fontSize={"12px"} status="info" rounded="md" textAlign={"left"}>
          <AlertIcon />
          This question serves to assess whether the patient exhibits this symptom, particularly after they have shown a general alignment with the
          broader characteristics of the disorder.
        </Alert>

        <FormControl id="question" isRequired mt="5">
          <FormLabel fontSize={"14px"} mb="1">
            Question
          </FormLabel>
          <Input
            type="text"
            placeholder="Do you experience..."
            value={qualifierQuestion?.question || ""}
            onChange={(e) => {
              setQualifierQuestion({ ...qualifierQuestion, question: e.target.value });
            }}
          />
        </FormControl>

        <FormControl id="meaning" mt="5">
          <FormLabel fontSize={"14px"} mb="1">
            Helper Text (Meaning)
          </FormLabel>
          <Input
            type="text"
            placeholder="This question is saying..."
            value={qualifierQuestion?.meaning || ""}
            onChange={(e) => {
              setQualifierQuestion({ ...qualifierQuestion, meaning: e.target.value });
            }}
          />
        </FormControl>

        <Flex gap="4">
          <FormControl id="meaning" isRequired mt="5">
            <FormLabel fontSize={"14px"} mb="1">
              Symptom Applicable Button Text
            </FormLabel>
            <Input
              type="text"
              placeholder="Yes"
              value={qualifierQuestion?.applicableButtonText || ""}
              onChange={(e) => {
                setQualifierQuestion({ ...qualifierQuestion, applicableButtonText: e.target.value });
              }}
            />
          </FormControl>
          <FormControl id="meaning" isRequired mt="5">
            <FormLabel fontSize={"14px"} mb="1">
              Symptom Unapplicable Button Text
            </FormLabel>
            <Input
              type="text"
              placeholder="No"
              value={qualifierQuestion?.unapplicableButtonText || ""}
              onChange={(e) => {
                setQualifierQuestion({ ...qualifierQuestion, unapplicableButtonText: e.target.value });
              }}
            />
          </FormControl>
        </Flex>

        <FormControl id="unsure" mt="5">
          <Checkbox
            isChecked={qualifierQuestion?.unsure || false}
            onChange={(e) => {
              setQualifierQuestion({ ...qualifierQuestion, unsure: e.target.checked });
            }}
            mx="4"
          >
            Show "Unsure" Button
          </Checkbox>
          <Checkbox
            isChecked={qualifierQuestion?.unsureApplicable || false}
            onChange={(e) => {
              setQualifierQuestion({ ...qualifierQuestion, unsureApplicable: e.target.checked });
            }}
            isDisabled={!qualifierQuestion?.unsure}
            mx="4"
          >
            Unsure Implies Symptom Applicable
          </Checkbox>
        </FormControl>
        <Box my="3">
          <Button
            colorScheme="blue"
            variant="outline"
            my={4}
            mx="2"
            px="10"
            isLoading={isAutogenerating}
            onClick={() => autogenerateQualifierQuestion(qualifierQuestion, setQualifierQuestion, symptom, setIsAutogenerating, toast)}
          >
            <Icon as={FaMagic} mr="2" /> Autogenerate
          </Button>
          <Button colorScheme="blue" type="submit" my={4} mx="2" px="10" isLoading={isLoading}>
            Save
          </Button>
          {qualifierQuestion?._id && (
            <>
              <br />
              <Button colorScheme="blue" variant="ghost" mx="2" px="10" onClick={() => setQuestionToDelete(qualifierQuestion)}>
                Delete
              </Button>
            </>
          )}
        </Box>
      </Box>
      <DeleteQuestion setQuestions={setQualifierQuestion} question={questionToDelete} setQuestion={setQuestionToDelete} type={"qualifier"} />
    </form>
  );
}
