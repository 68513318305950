import React, { useState, useRef } from "react";

export default function Symptoms(props) {
  const stateChanger = props.stateChanger;
  const nextLoaderSC = props.nextLoaderSC;
  const symptomsCaught = props.symptomsCaught;
  const symptomsCaughtSC = props.symptomsCaughtSC;
  const allSymptoms = props.allSymptoms;
  const allSymptomCategories = props.allSymptomCategories;
  const totalSymptomsSC = props.totalSymptomsSC;
  const interactionSymptomsSC = props.interactionSymptomsSC;

  const [symptomsDisapproved, setSymptomsDisapproved] = useState([]);
  const [newSymptomsCounter, setNewSymptomsCounter] = useState(0);
  const symptomMenu = useRef(null);
  const symptomMenuSelects = useRef([]);

  function confirm() {
    const updatedSymptomsCaught = symptomsCaught.filter((s) => !symptomsDisapproved.includes(s));
    let newSymptoms = [];
    Array(newSymptomsCounter)
      .fill(0)
      .map((x, i) => {
        if (symptomMenuSelects.current[i].value != "") {
          newSymptoms.push(symptomMenuSelects.current[i].value.split("[S.")[1].split("]")[0]);
        }
      });
    const predictedSymptoms = updatedSymptomsCaught.concat(newSymptoms);
    console.log("Edited Symptoms: " + predictedSymptoms);
    totalSymptomsSC((prevS) => prevS.concat(predictedSymptoms));
    interactionSymptomsSC(predictedSymptoms);
    symptomsCaughtSC([]);
    nextLoaderSC(false);
    stateChanger(false);
  }

  return (
    <>
      <div
        className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{ backgroundColor: "rgb(0,0,0,0.5)" }}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            style={{ backgroundColor: "#F6F5FF" }}
          >
            <div className="flex -mb-7">
              <h3 className="text-3xl font-medium  text-gray-800 m-4 mb-6" id="modal-title" style={{ color: "#C0BFFF" }}>
                Symptom Indicators
              </h3>
            </div>
            <form className="mt-6" action="#">
              <label className="block mx-4 mb-4" htmlFor="predictions">
                <div>
                  {symptomsCaught.map((s) => {
                    for (let s2 = 0; s2 <= allSymptoms.length; s2++) {
                      if (allSymptoms[s2].Code == s) {
                        return (
                          <>
                            <div className="bg-med bg-opacity-75 text-background text-xs p-2 rounded-md mt-1">
                              <span className={symptomsDisapproved.includes(s) ? "line-through" : ""}>
                                {"[S." + allSymptoms[s2].Code + "] " + allSymptoms[s2].Description}
                              </span>{" "}
                              &nbsp;
                              <span
                                className="inline-block underline cursor-pointer text-dark text-opacity-75 font-bold"
                                onClick={() => {
                                  symptomsDisapproved.includes(s)
                                    ? setSymptomsDisapproved((prevSD) => prevSD.filter((prevS) => prevS != s))
                                    : setSymptomsDisapproved((prevSD) => [...prevSD, s]);
                                }}
                              >
                                x
                              </span>
                            </div>
                          </>
                        );
                      }
                    }
                  })}
                </div>
              </label>
              <label ref={symptomMenu}>
                {Array(newSymptomsCounter)
                  .fill(0)
                  .map((x, i) => (
                    <>
                      <input
                        className="mx-4 mt-1 p-3 mb-2 rounded-md bg-light bg-opacity-50 text-xs border-none text-med w-[19rem] placeholder-med"
                        ref={(element) => {
                          symptomMenuSelects.current[i] = element;
                        }}
                        list="symptoms"
                        placeholder="Add a symptom..."
                      />
                      <datalist id="symptoms">
                        {allSymptoms.map((sym) => {
                          return <option value={"[S." + sym.Code + "] " + sym.Description} />;
                        })}
                      </datalist>
                    </>
                  ))}
              </label>
              <div className="w-full text-center text-4xl text-med mt-2 cursor-pointer" onClick={() => setNewSymptomsCounter(newSymptomsCounter + 1)}>
                +
              </div>
              <div className="mt-6 w-[19rem] mb-4 mx-4 rounded-lg text-background bg-dark">
                <button type="button" className="w-full py-2 rounded-lg font-medium" onClick={confirm}>
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
