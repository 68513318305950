import { render } from "@testing-library/react";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export default function Symptoms(props) {
  return (
    <>
      <div id="no_phone" className="hidden text-med m-7 bg-light bg-opacity-50 p-7 rounded-md font-medium text-xl w-screen">
        Please make sure to access the intake design app on a larger screen such as a laptop or desktop for the best experience.
      </div>
      <div className="w-screen h-screen flex">
        <div className="p-8 pt-12 h-screen w-[24rem]">
          <img src="/images/App/main_logo.png" className="h-20" />
          <div className="mt-28 mb-24 p-4 text-[65px] leading-[5rem] font-semibold">
            <span className="text-light">Symptom</span>
            <br />
            <span className="text-med">Creation</span>
            <br />
            <span className="text-dark">App</span>
          </div>
          <div className="m-6">
            <span className="text-light text-sm">powered by</span>
            <img src="/images/logo.png" className="h-10 m-5" />
          </div>
        </div>

        <div className="bg-light bg-opacity-25 w-full h-screen min-w-[62.5rem] min-h-[45rem]">
          <div className="rounded-sm bg-med bg-opacity-25 h-[90vh] m-9 mr-12 ml-12 p-8 border-t-8 border-dark min-h-[41rem]"></div>
        </div>
      </div>
    </>
  );
}
