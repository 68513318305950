import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { FaMagic } from "react-icons/fa";
import { DashStateContext } from "../../pages/Design";
import { autogenerateFollowupQuestion } from "./helpers/autogenerateFollowupQuestion";
import { createUpdateFollowupQuestion } from "./helpers/createUpdateFollowupQuestion";

export default function OpenEnded({ oeFollowup, setOEFollowup, symptom, setFollowupQuestions }) {
  const { userId } = useContext(DashStateContext);
  const [followupQuestion, setFollowupQuestion] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAutogenerating, setIsAutogenerating] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFollowupQuestion({});
    oeFollowup?._id && setFollowupQuestion(oeFollowup);
  }, [oeFollowup]);

  return (
    <Modal isOpen={oeFollowup} onClose={() => setOEFollowup(null)} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create open-ended followup</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              createUpdateFollowupQuestion(
                e,
                followupQuestion,
                setFollowupQuestions,
                setFollowupQuestion,
                symptom?.sid,
                false,
                userId,
                setIsLoading,
                toast
              );
              setOEFollowup(null);
            }}
          >
            {" "}
            <FormControl id="question" isRequired>
              <FormLabel fontSize={"14px"} mb="1">
                Question
              </FormLabel>
              <Input
                type="text"
                placeholder="What is causing..."
                value={followupQuestion?.question}
                onChange={(e) => {
                  setFollowupQuestion({ ...followupQuestion, question: e.target.value });
                }}
              />
            </FormControl>
            <FormControl id="meaning" mt="5">
              <FormLabel fontSize={"14px"} mb="1">
                Helper Text (Meaning)
              </FormLabel>
              <Input
                type="text"
                placeholder="This question is saying..."
                value={followupQuestion?.meaning}
                onChange={(e) => {
                  setFollowupQuestion({ ...followupQuestion, meaning: e.target.value });
                }}
              />
            </FormControl>
            <Box my="3" textAlign={"center"}>
              <Button
                colorScheme="blue"
                variant="outline"
                my={4}
                mx="2"
                px="10"
                isLoading={isAutogenerating}
                onClick={() => autogenerateFollowupQuestion(followupQuestion, setFollowupQuestion, symptom, setIsAutogenerating, toast)}
              >
                <Icon as={FaMagic} mr="2" /> Autogenerate
              </Button>
              <Button colorScheme="blue" type="submit" my={4} mx="2" px="10" isLoading={isLoading}>
                Save
              </Button>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
