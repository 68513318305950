import axios from "axios";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

export function generateQuestion(state, symptom, qa, stateType, setNewQuestionText, setLoadingQuestion) {
  setLoadingQuestion(true);
  let config = {
    method: "POST",
    url: "https://webserver.brightlight.ai/generateQuestion",
    headers: { "Content-Type": "application/json", Authorization: "Bearer " + state.jwt },
    data: qa
      ? JSON.stringify({ id: jwtDecode(state.jwt).id, symptom: symptom, stateType: stateType, qa: qa })
      : JSON.stringify({ id: jwtDecode(state.jwt).id, symptom: symptom, stateType: stateType }),
  };
  axios(config)
    .then(function (response) {
      console.log("generateQuestion success: ");
      console.log(response.data);
      if (response.data) setNewQuestionText(response.data[0].text.trim());
      setLoadingQuestion(false);
    })
    .catch(function (error) {
      console.log("generateQuestion error: ");
      console.log(error);
      setLoadingQuestion(false);
    });
}

export function generateMeaning(state, question, setNewQuestionMeaning, setLoadingMeaning) {
  setLoadingMeaning(true);
  let config = {
    method: "POST",
    url: "https://webserver.brightlight.ai/generateMeaning",
    headers: { "Content-Type": "application/json", Authorization: "Bearer " + state.jwt },
    data: JSON.stringify({ id: jwtDecode(state.jwt).id, question: question }),
  };
  axios(config)
    .then(function (response) {
      console.log("generateMeaning success: ");
      console.log(response.data);
      if (response.data) setNewQuestionMeaning(response.data[0].text.trim());
      setLoadingMeaning(false);
    })
    .catch(function (error) {
      console.log("generateMeaning error: ");
      console.log(error);
      setLoadingMeaning(false);
    });
}
