import { Input } from "@chakra-ui/react";

export function renderEditableField(editStatus, currentEdit, setCurrentEdit, index, field, value, type, truncate) {
  return editStatus[index] ? (
    <Input
      type={type}
      defaultValue={value}
      onChange={(e) => {
        setCurrentEdit({
          ...currentEdit,
          [index]: { ...currentEdit[index], [field]: e.target.value },
        });
      }}
      size="sm"
    />
  ) : truncate ? (
    value?.split(" ").slice(0, truncate).join(" ") + (value?.split(" ").length > truncate ? "..." : "")
  ) : (
    value
  );
}
