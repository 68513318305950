import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Textarea,
  Input,
  Portal,
  Flex,
  ButtonGroup,
  PopoverFooter,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Select,
  Badge,
  Spacer,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useState, useRef, useContext } from "react";
import { createSymptom } from "./helpers/createSymptom";
import { updateSymptom } from "./helpers/updateSymptom";
import { autogenerateSymptom } from "./helpers/autogenerateSymptom";
import { DashStateContext } from "../../pages/Design";
import { FaMagic } from "react-icons/fa";
import { set } from "date-fns";

export default function CreateSymptom({ allSymptoms, setSymptoms, disorder }) {
  const { userId } = useContext(DashStateContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metadata, setMetadata] = useState("");
  const [priority, setPriority] = useState();
  const [symptomToImport, setSymptomToImport] = useState("");
  const [isAutogenerating, setIsAutogenerating] = useState(false);

  const createClose = useRef();
  const toast = useToast();

  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button px="10" my="6" mx="auto" colorScheme="blue" variant="outline" rounded="none" boxShadow={"md"}>
          Create a new symptom
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            Create Symptom
          </PopoverHeader>
          <PopoverCloseButton ref={createClose} />
          <PopoverBody>
            <Tabs>
              <TabList>
                <Tab>
                  <Badge colorScheme="facebook" px="2" py="1" rounded="md">
                    Create New
                  </Badge>
                </Tab>
                <Tab>
                  <Badge colorScheme="twitter" px="2" py="1" rounded="md">
                    Import Existing
                  </Badge>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Input variant="flushed" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                  <Textarea
                    mt="3"
                    border="0"
                    borderBottom="1px"
                    borderColor="gray.200"
                    variant="flushed"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></Textarea>
                  <Textarea
                    mt="3"
                    border="0"
                    borderBottom="1px"
                    borderColor="gray.200"
                    variant="flushed"
                    placeholder="Associated Metadata"
                    value={metadata}
                    onChange={(e) => setMetadata(e.target.value)}
                  ></Textarea>
                  <Input
                    mt="3"
                    border="0"
                    borderBottom="1px"
                    borderColor="gray.200"
                    variant="flushed"
                    type="number"
                    placeholder="Priority (1 - X)"
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  />
                </TabPanel>
                <TabPanel>
                  <FormControl id="symptom">
                    <FormLabel>Select Existing Symptom</FormLabel>
                    <Select
                      variant="flushed"
                      type="text"
                      placeholder="-"
                      border="0"
                      borderBottom="1px"
                      borderColor="gray.200"
                      value={symptomToImport}
                      onChange={(e) => setSymptomToImport(e.target.value)}
                    >
                      {allSymptoms
                        .filter((s) => !s.associatedDisorders.includes(disorder?.did))
                        .map((symptom) => (
                          <option value={symptom.sid}>{symptom.name}</option>
                        ))}
                    </Select>
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
          <PopoverFooter border="0">
            <Flex>
              <IconButton
                icon={<FaMagic />}
                variant="ghost"
                mr="2"
                ml="1"
                mt="-1.5"
                color="blue.500"
                cursor="pointer"
                onClick={() =>
                  !isAutogenerating && autogenerateSymptom(name, setName, setDescription, disorder, allSymptoms, setIsAutogenerating, toast)
                }
              />
              <Spacer />
              <ButtonGroup size="sm">
                <Button colorScheme="blue" variant="ghost" onClick={() => createClose.current.click()}>
                  Go Back
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    if (symptomToImport) {
                      const updatedSymptom = allSymptoms.find((s) => s.sid == symptomToImport);
                      updatedSymptom?.associatedDisorders?.push(disorder?.did);
                      updateSymptom(setSymptoms, updatedSymptom, toast);
                      setSymptomToImport("");
                    } else {
                      name && createSymptom(setSymptoms, name, description, metadata, priority, disorder?.did, userId, toast);
                      setName("");
                      setDescription("");
                      setMetadata("");
                      setPriority("");
                    }
                    createClose.current.click();
                  }}
                >
                  Add Symptom
                </Button>
              </ButtonGroup>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
