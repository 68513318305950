import React, { useState, useEffect, useRef, createContext } from "react";
import { Avatar, AvatarBadge, Badge, Flex, Spacer, Box, Image, Text } from "@chakra-ui/react";
import Disorders from "../components/Design/Disorders";

export const DashStateContext = createContext();

export default function Design() {
  return (
    <DashStateContext.Provider value={{ userId: "000" }}>
      <Flex mx="10" mt="8">
        <Text fontSize={"24px"} fontWeight={"bold"} mb={"5"}>
          <Image src={"../images/build_logo_bw.png"} h="16" />
        </Text>
        <Spacer />
        <Flex>
          <Avatar size="lg" name="Alex Mitchell" src="../images/brydon.jpeg">
            <AvatarBadge boxSize="0.75em" bg={"green.300"} />
          </Avatar>
          <Box ml="4" mt="2.5">
            <Text fontWeight={"semibold"}>Dr. Brydon Blacklaws</Text>
            <Text fontWeight={"regular"} fontSize="12px">
              ER Physician
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Box>
        <Text bgGradient="linear(to-r, black, gray.400)" bgClip="text" fontSize="3xl" fontWeight="bold" textAlign={"center"}>
          Brightlight Intake Design Studio {"  "}
        </Text>
        <Disorders />
      </Box>
    </DashStateContext.Provider>
  );
}
