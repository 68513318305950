import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Textarea,
  Input,
  Portal,
  Flex,
  ButtonGroup,
  PopoverFooter,
  useToast,
} from "@chakra-ui/react";
import { useState, useRef, useContext } from "react";
import { createDisorder } from "./helpers/createDisorder";
import { DashStateContext } from "../../pages/Design";

export default function CreateDisorder({ setDisorders }) {
  const { userId } = useContext(DashStateContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metadata, setMetadata] = useState("");
  const [priority, setPriority] = useState();
  const createClose = useRef();
  const toast = useToast();

  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button px="10" my="6" mx="auto" colorScheme="blue" rounded="none" boxShadow={"md"}>
          Create a new disorder
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            Create Disorder
          </PopoverHeader>
          <PopoverCloseButton ref={createClose} />
          <PopoverBody>
            <Input variant="flushed" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
            <Textarea
              mt="3"
              border="0"
              borderBottom="1px"
              borderColor="gray.200"
              variant="flushed"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Textarea>
            <Textarea
              mt="3"
              border="0"
              borderBottom="1px"
              borderColor="gray.200"
              variant="flushed"
              placeholder="Associated Metadata"
              value={metadata}
              onChange={(e) => setMetadata(e.target.value)}
            ></Textarea>
            <Input
              mt="3"
              border="0"
              borderBottom="1px"
              borderColor="gray.200"
              variant="flushed"
              type="number"
              placeholder="Priority (1 - X)"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            />
          </PopoverBody>
          <PopoverFooter border="0">
            <Flex justifyContent="end">
              <ButtonGroup size="sm">
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  onClick={() => {
                    createClose.current.click();
                  }}
                >
                  Go Back
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    createDisorder(setDisorders, name, description, metadata, priority, userId, toast);
                    setName("");
                    setDescription("");
                    setMetadata("");
                    setPriority("");
                    createClose.current.click();
                  }}
                >
                  Add Disorder
                </Button>
              </ButtonGroup>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
