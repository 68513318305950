import React, { useState, useRef, useEffect } from "react";
import { crud } from "../crudRequests";
import { generateQuestion, generateMeaning } from "../intakeRequests";
import { AiOutlinePartition } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

export default function AddQuestionModal(props) {
  const state = props.state;
  const stateType = props.stateType;
  const stateChanger = props.stateChanger;
  const selectedSymptom = props.selectedSymptom;
  const allSymptoms = props.allSymptoms;
  const allQuestions = props.allQuestions;
  const allDisorderQuestions = props.allDisorderQuestions;
  const pastQInfoForAddition = props.pastQInfoForAddition;

  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [loadingMeaning, setLoadingMeaning] = useState(false);

  const [newQuestionText, setNewQuestionText] = useState("");
  const [newQuestionMeaning, setNewQuestionMeaning] = useState("");

  const [mplOrOpen, setMplOrOpen] = useState(true);
  const [symptarg, setSymptarg] = useState(true);
  const [durationresp, setDurationresp] = useState(false);
  const [mandresp, setMandresp] = useState(false);

  const [optionsCounter, setOptionsCounter] = useState(1);
  const optionsEndRef = useRef(null);
  const optionValue = useRef([]);
  const acknowledgementValue = useRef([]);

  const targetedSymptom = useRef(null);
  const positiveAcknowledgement = useRef(null);
  const negativeAcknowledgement = useRef(null);

  const [warning, setWarning] = useState("");

  useEffect(() => {
    optionsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [optionsCounter]);

  function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function add() {
    const id = makeid(10);

    let c;
    c =
      Math.max.apply(
        null,
        stateType == "symptom_questions" ? allQuestions.map((q) => parseInt(q.code)) : allDisorderQuestions.map((q) => parseInt(q.code))
      ) + 1;

    if (stateType == "symptom_questions") {
      let options = {};
      if (mplOrOpen) {
        [...Array(10).keys()].map((key) => {
          console.log(optionValue.current[key].value);
          if (optionValue.current[key].value) {
            options[optionValue.current[key].value] = acknowledgementValue.current[key].value;
          }
        });
      } else if (!mplOrOpen && symptarg) {
        options["Presented"] = positiveAcknowledgement.current.value;
        options["Unpresented"] = negativeAcknowledgement.current.value;
      }

      let pathways, relevantSymptoms;

      if (!mplOrOpen && symptarg) relevantSymptoms = [targetedSymptom.current.value.split("[S.")[1].split("]")[0]];

      if (pastQInfoForAddition) {
        let past_pws = String(pastQInfoForAddition[0].pathways);
        if (past_pws.includes("{")) {
          pathways = past_pws.replace("})", "},{" + String(pastQInfoForAddition[0].code) + "*" + pastQInfoForAddition[1] + "})");
          let layerno = pathways.split("=")[pathways.split("=").length - 1];
          pathways = pathways.replace("})=" + layerno, "})=" + (parseInt(layerno) + 1));
        } else {
          pathways = "(" + String(selectedSymptom) + ")=1```({" + String(pastQInfoForAddition[0].code) + "*" + pastQInfoForAddition[1] + "})=1";
        }
      } else {
        pathways = "(" + String(selectedSymptom) + ")=1";
      }

      let addBody = {
        id: id,
        code: parseInt(c),
        pathways: pathways,
        question: newQuestionText,
        meaning: newQuestionMeaning,
        options: options,
        freeflow: !mplOrOpen,
        targeted: symptarg,
        duration: durationresp,
        mandatory: mandresp,
        //relevantSymptoms: relevantSymptoms,
        approvals: [{ did: state.doctor.did, photo: state.doctor.photo }],
      };
      crud(state, [
        {
          db: "intake",
          collection: "symptom_questions",
          parameters: [addBody],
          method: "insertOne",
        },
      ]).then(() => stateChanger(false));
    } else {
      let addBody = {
        id: id,
        code: parseInt(c),
        question: newQuestionText,
        meaning: newQuestionMeaning,
        symptom: selectedSymptom,
        approvals: [{ did: state.doctor.did, photo: state.doctor.photo }],
      };
      crud(state, [
        {
          db: "intake",
          collection: "disorder_questions",
          parameters: [addBody],
          method: "insertOne",
        },
      ]).then(() => stateChanger(false));
    }
  }
  return (
    <>
      <div
        className="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        style={{ backgroundColor: "rgb(0,0,0,0.5)" }}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <div
            className="relative inline-block px-4 pt-4 pb-4 overflow-hidden text-left align-bottom transition-all transform rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6 sm:align-middle"
            style={{ backgroundColor: "#F6F5FF" }}
          >
            <div className="flex -mb-7">
              <h3 className="text-3xl font-medium text-gray-800 m-4 mb-8" id="modal-title" style={{ color: "#C0BFFF" }}>
                create question
              </h3>
              <div className="float-right ml-28 underline text-med cursor-pointer" onClick={() => stateChanger(false)}>
                x
              </div>
            </div>
            <form className="mt-6 mx-5" action="#">
              <div className={"mt-0.5 font-regular text-sm rounded-md bg-dark border-none text-background w-[22.5rem]"}>
                <span
                  onClick={() =>
                    generateQuestion(
                      state,
                      allSymptoms.find((s) => s.symId === selectedSymptom).shortDesc,
                      pastQInfoForAddition ? pastQInfoForAddition[0].question + "|" + pastQInfoForAddition[1] : "",
                      stateType,
                      setNewQuestionText,
                      setLoadingQuestion
                    )
                  }
                  className="pl-1.5 inline-block text-lg"
                >
                  {loadingQuestion ? (
                    <FaSpinner className="-mt-1 animate-spin text-white inline cursor-pointer mx-0.5" size={16} />
                  ) : (
                    <AiOutlinePartition className="-mt-1 text-white inline cursor-pointer" size={20} />
                  )}
                </span>{" "}
                <textarea
                  className="text-sm mb-[-30px] p-[0.33rem] pl-3 ml-[0.125rem] rounded-md w-[20.5rem] text-off"
                  placeholder="create question..."
                  value={newQuestionText}
                  onChange={(e) => setNewQuestionText(e.target.value)}
                ></textarea>
              </div>
              <div className={"mt-8 font-regular text-sm rounded-md bg-med border-none text-background w-[22.5rem]"}>
                <span onClick={() => generateMeaning(state, newQuestionText, setNewQuestionMeaning, setLoadingMeaning)} className="pl-1.5 text-lg">
                  {loadingMeaning ? (
                    <FaSpinner className="-mt-1 animate-spin text-white inline cursor-pointer mx-0.5" size={16} />
                  ) : (
                    <AiOutlinePartition className="-mt-1 text-white inline cursor-pointer" size={20} />
                  )}
                </span>{" "}
                <textarea
                  className="p-[0.33rem] mb-[-70px] pl-3 ml-[0.125rem] rounded-md w-[20.5rem] text-sm text-off"
                  placeholder="add meaning..."
                  rows={4}
                  value={newQuestionMeaning}
                  onChange={(e) => setNewQuestionMeaning(e.target.value)}
                ></textarea>
              </div>
              {stateType == "symptom_questions" && (
                <>
                  {/*
                   *******************************************************************
                   *********************** OLD INJECTION TOOL ************************
                   *******************************************************************
                   */}

                  <div className="mt-20 font-medium text-lg">
                    <div className="flex">
                      <div
                        onClick={() => {
                          setMplOrOpen(true);
                        }}
                        className={
                          mplOrOpen == true
                            ? "rounded-tl-md rounded-bl-md bg-dark text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                            : "rounded-tl-md rounded-bl-md bg-med bg-opacity-50 text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                        }
                      >
                        multiple choice
                      </div>
                      <div
                        onClick={() => {
                          setMplOrOpen(false);
                        }}
                        className={
                          mplOrOpen == false
                            ? "rounded-tr-md rounded-br-md bg-dark text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                            : "rounded-tr-md rounded-br-md bg-med bg-opacity-50 text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                        }
                      >
                        open
                      </div>
                    </div>
                  </div>

                  {/************************************* MULTIPLE CHOICE *************************************/}

                  <div className={mplOrOpen == true ? "flex float-left mt-4 mr-6 pb-1 overflow-auto w-[22.5rem]" : "hidden"}>
                    <div className="mt-2 flex">
                      {[...Array(10).keys()].map((key) => {
                        return (
                          <div className={optionsCounter >= key ? "pr-5" : "hidden"}>
                            <input
                              className="text-xs rounded-md p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                              placeholder="option"
                              ref={(opt) => (optionValue.current[key] = opt)}
                            />
                            <textarea
                              className="text-xs rounded-md h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                              placeholder="acknowledgement"
                              ref={(ack) => (acknowledgementValue.current[key] = ack)}
                            />
                          </div>
                        );
                      })}
                      <img
                        src="/images/App/plus.png"
                        className="h-8 p-1 cursor-pointer"
                        onClick={() => {
                          optionsCounter != 7
                            ? setOptionsCounter(optionsCounter + 1)
                            : setWarning("7 is the maximum number of options allowed per question.");
                        }}
                      />
                      <div ref={optionsEndRef} />
                    </div>
                  </div>

                  {/**************************************** OPEN ENDED ****************************************/}

                  <div className={mplOrOpen != true ? "float-left mt-4 mr-5" : "hidden"}>
                    <div className="flex">
                      <div>
                        <div
                          className={
                            symptarg == true
                              ? "float-left inline-block bg-dark text-center p-5 px-[2.2rem] rounded-md text-lg w-max text-background flex cursor-pointer"
                              : "bg-med bg-opacity-70 text-center p-8 px-0 rounded-md text-lg w-max text-background flex cursor-pointer"
                          }
                          onClick={() => {
                            setSymptarg(!symptarg);
                          }}
                        >
                          <img
                            src="/images/App/target.png"
                            className={symptarg == true ? "h-[2.4rem] -mt-1.5 -mb-6 pl-1" : "h-[3.5rem] -mt-2 -mb-6 pl-7"}
                          />
                          <span className={symptarg == true ? "leading-5 ml-2.5 mt-0.5 mb-1 pr-1" : "leading-6 ml-3 mr-0.5 -mt-0.5 pr-7"}>
                            {symptarg == true ? (
                              <span>targeted</span>
                            ) : (
                              <span>
                                non-
                                <br />
                                targeted
                              </span>
                            )}
                          </span>
                        </div>
                        <br />
                        <input
                          className={
                            symptarg == true
                              ? "mt-[0.22rem] p-3 mb-2 rounded-md bg-dark bg-opacity-80 text-xs border-none text-background w-[12.5rem] placeholder-background"
                              : "hidden"
                          }
                          ref={targetedSymptom}
                          list="symptoms"
                          placeholder="select a symptom"
                        />
                        <datalist id="symptoms">
                          {allSymptoms.map((sym) => {
                            return <option value={"[S." + sym.symId + "] " + sym.shortDesc} />;
                          })}
                        </datalist>
                      </div>
                      <div className={symptarg == true ? "ml-2" : "hidden"}>
                        <img src="/images/App/positive.png" className="absolute h-4 mt-1 ml-2.5" />
                        <textarea
                          className="text-xs rounded-md ml-1.5 mr-8 h-[4.14rem] p-1 mb-[5.25px] pl-6 w-36 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                          placeholder="acknowledgement"
                          ref={positiveAcknowledgement}
                        />
                        <br />
                        <img src="/images/App/negative.png" className="absolute -mt-1 h-4 ml-2.5" />
                        <textarea
                          className="text-xs rounded-md ml-1.5 mr-8 h-[2.48rem] -mt-2 p-1 pl-6 w-36 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                          placeholder="acknowledgement"
                          ref={negativeAcknowledgement}
                        />
                      </div>
                    </div>
                  </div>

                  {/************************************* DURATION/MANDATORY *************************************/}

                  <div className="mt-[9.7rem]">
                    <div className="text-md rounded-md text-background ">
                      <div className="flex">
                        <span className="tooltip">
                          <div
                            className={
                              durationresp == true
                                ? "flex bg-dark p-3 rounded-md text-sm cursor-pointer mb-[0.33rem] "
                                : "flex bg-med bg-opacity-50 p-3 rounded-md text-sm cursor-pointer mb-[0.33rem] "
                            }
                            onClick={() => {
                              setDurationresp(!durationresp);
                            }}
                          >
                            <img src="/images/App/calendar.svg" className="h-[35px] mt-1.5 ml-2.5" />
                            <div className="mt-1 mx-4 my-0.5">
                              duration
                              <br />
                              question
                            </div>
                          </div>
                          <span className="tooltiptext">Question that is requiring a standard duration question next</span>
                        </span>
                        <span className="tooltip">
                          <div
                            className={
                              mandresp == true
                                ? "flex bg-dark p-3 rounded-md text-sm ml-6 pl-5 pr-5 cursor-pointer mb-[0.33rem]"
                                : "flex bg-med bg-opacity-50 p-3 rounded-md text-sm ml-6 pl-5 pr-5 cursor-pointer mb-[0.33rem]"
                            }
                            onClick={() => {
                              setMandresp(!mandresp);
                            }}
                          >
                            <img src="/images/App/mandatory.png" className="h-8 mt-1.5 mb-2 ml-1.5" />
                            <div className="mt-1 ml-4 mr-2">
                              mandatory
                              <br />
                              question
                            </div>
                          </div>
                          <span className="tooltiptext">Question that must be asked given such context</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={`${
                  stateType == "disorder_questions" ? `mt-20` : `mt-6`
                } w-[22.5rem] mb-4 rounded-lg text-background bg-gradient-to-r from-dark to-pink-500`}
              >
                <button type="button" className="w-full py-2 rounded-lg font-medium" onClick={add}>
                  <img src={"/images/App/create.svg"} className="h-5 -mt-1.5 inline-block" /> create question
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
