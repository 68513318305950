import { DashStateContext } from "../../pages/Design";
import {
  Text,
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Badge,
  Heading,
  Card,
  CardBody,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import Qualifier from "./Qualifier";
import Duration from "./Duration";
import Followups from "./Followups";
import Risk from "./Risk";

export default function Questions({ symptom, setSymptom, setSymptoms }) {
  const toast = useToast();
  const { userId } = useContext(DashStateContext);

  return (
    <Drawer onClose={() => setSymptom(false)} isOpen={symptom} size={"xl"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Symptom Configuration</DrawerHeader>
        <DrawerBody>
          <Card bgGradient="linear(to-r, #0f0c29, #302b63, #24243e)" color="white">
            <CardBody>
              <Heading size="md" mb="3" fontSize="18px">
                {symptom?.name}
              </Heading>
              <Text fontSize="14px">{symptom?.description}</Text>
            </CardBody>
          </Card>
          <Box mt="5">
            <Tabs>
              <TabList>
                <Tab>
                  <Badge colorScheme={"green"} fontSize="12px" rounded="md" p="1" px="2">
                    Qualifier
                  </Badge>
                </Tab>
                <Tab>
                  <Badge colorScheme={"purple"} fontSize="12px" rounded="md" p="1" px="2">
                    Duration
                  </Badge>
                </Tab>
                <Tab>
                  <Badge colorScheme={"orange"} fontSize="12px" rounded="md" p="1" px="2">
                    Followup(s)
                  </Badge>
                </Tab>
                <Tab>
                  <Badge colorScheme={"red"} fontSize="12px" rounded="md" p="1" px="2">
                    Risk
                  </Badge>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Qualifier symptom={symptom} />
                </TabPanel>
                <TabPanel>
                  <Duration symptom={symptom} />
                </TabPanel>
                <TabPanel>
                  <Followups symptom={symptom} />
                </TabPanel>
                <TabPanel>
                  <Risk symptom={symptom} setSymptoms={setSymptoms} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
