import { supplementaryCrud } from "../../../crudRequests";

export async function updateDisorder(setDisorders, index, updatedDisorder, toast) {
  try {
    let { _id, ...updatedDisorderContent } = updatedDisorder;
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "disorders",
        parameters: [{ did: updatedDisorder.did }, { $set: updatedDisorderContent }],
        method: "updateOne",
      },
    ]);
    setDisorders((prev) => {
      const newDisorders = [...prev];
      newDisorders[index] = updatedDisorder;
      return newDisorders;
    });
    toast({
      title: "Successfully updated disorder",
      description: updatedDisorder.name + " and it's metadata has been updated.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Failed to update disorder",
      description: err?.message || "An error occurred while updating disorder.",
      status: "error",
      isClosable: true,
    });
  }
}
