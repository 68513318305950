import { set } from "date-fns";
import { supplementaryCrud } from "../../../crudRequests";

export async function deleteDisorder(setDisorders, disorderToDelete, toast) {
  try {
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "disorders",
        parameters: [{ did: disorderToDelete.did }],
        method: "deleteOne",
      },
    ]);
    setDisorders((prev) => prev.filter((disorder) => disorder.did !== disorderToDelete.did));
    toast({
      title: "Successfully deleted disorder",
      description: disorderToDelete.name + " and it's metadata has been deleted.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Failed to delete disorder",
      description: err?.message || "An error occurred while deleting disorder.",
      status: "error",
      isClosable: true,
    });
  }
}
