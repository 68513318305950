import { render } from "@testing-library/react";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { verifyUser, logoutUser } from "../authRequests";
import { ConfigurationFunctions } from "../additional_files/configurationFunctions";
import { Switch } from "@headlessui/react";
import { RiEditCircleFill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import {
  BsInfoCircle,
  BsFillPatchCheckFill,
  BsPatchCheck,
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";
import Swal from "sweetalert2";
import AddQuestionModal from "../components/Configuration.AddQuestionModal";
import EditQuestionModal from "../components/Configuration.EditQuestionModal";

export default function Configuration(props) {
  const { state } = useLocation();

  useEffect(
    function () {
      verifyUser(state ? state.jwt : "", loadedRef);
    },
    [state]
  );

  //console.log(state);
  const [
    FUNC_initializeSymptomsAndDisorders,
    FUNC_initializeQuestions,
    FUNC_queryQuestions,
    FUNC_toolSymptomsCaught,
    FUNC_deleteQuestion,
    FUNC_approveQuestion,
    FUNC_disapproveQuestion,
    FUNC_changeDisorderPriority,
    loadedRef,
    STATE_queriedQuestions,
    STATE_queriedDisorderQuestions,
    STATE_allQuestions,
    STATE_allDisorderQuestions,
    STATE_allSymptoms,
    STATE_allDisorders,
    STATE_selectedDisorder,
    STATE_setSelectedDisorder,
    STATE_selectedSymptom,
    STATE_setSelectedSymptom,
    STATE_questionsToUpdate,
  ] = ConfigurationFunctions();

  const patientInputRef = useRef(null);
  const [STATE_viewType, STATE_setViewType] = useState("individual");
  const [STATE_highlightedOption, STATE_setHighlightedOption] = useState([
    0, 0, 0, 0,
  ]);
  const [STATE_symptomModelVersion, STATE_setSymptomModelVersion] =
    useState(true);
  const [STATE_symptomsCaught, STATE_setSymptomsCaught] = useState([]);
  const [STATE_followupQuestions, STATE_setFollowupQuestions] = useState([]);
  const [STATE_addQuestionToggle, STATE_setAddQuestionToggle] = useState(false);
  const [STATE_editQuestionToggle, STATE_setEditQuestionToggle] =
    useState(false);
  const [STATE_pastQInfoForAddition, STATE_setPastQInfoForAddition] =
    useState(null);
  const [STATE_questionToEdit, STATE_setQuestionToEdit] = useState(null);

  useEffect(() => {
    FUNC_initializeSymptomsAndDisorders(state);
    FUNC_initializeQuestions(state);
  }, []);

  useEffect(() => {
    FUNC_queryQuestions(
      STATE_selectedSymptom,
      STATE_allQuestions,
      STATE_allDisorderQuestions,
      state,
      STATE_viewType
    );
  }, [STATE_selectedSymptom, STATE_viewType]);

  useEffect(() => {
    if (!STATE_addQuestionToggle && !STATE_editQuestionToggle) {
      STATE_setPastQInfoForAddition(null);
      FUNC_initializeQuestions(state, STATE_selectedSymptom, STATE_viewType);
    }
  }, [
    STATE_addQuestionToggle,
    STATE_editQuestionToggle,
    STATE_questionsToUpdate,
  ]);

  function disorderQuestion(q) {
    let approvedDoc = q.approvals.find((doc) => doc.did == state.doctor.did);
    return (
      <div className="ml-3 mt-2 mr-10 mb-6">
        {q.approvals && STATE_viewType == "central" && (
          <div className="bg-dark bg-opacity-80 mx-auto h-[1.2rem] text-center w-[11rem] rounded-t-xl p-1">
            {q.approvals.slice(0, 5).map((approvalDoc, i) => {
              return (
                <>
                  <img
                    key={i}
                    src={approvalDoc.photo}
                    className="inline-block m-0.5 w-6 h-6 rounded-full z-50"
                  />
                </>
              );
            })}
            {q.approvals.length > 5 && (
              <div className="inline bg-light rounded-full ml-0.5 py-0.5 px-1 text-[10px] text-dark border border-dark">
                +{q.approvals.length - 5}
              </div>
            )}
          </div>
        )}
        <div className="w-[20rem] h-[10rem] mb-2 px-6 py-4 rounded-full bg-gray-200 bg-opacity-40 text-dark text-opacity-75">
          <div className="ml-3">
            <div className="inline-block text-xs bg-med rounded-full text-dark ml-3 mr-2 px-2 py-1">
              Q.{q.code}
            </div>
            {STATE_viewType == "individual" ? (
              <>
                <AiFillDelete
                  onClick={() => FUNC_deleteQuestion(q, state)}
                  className="inline float-right mr-6 mt-0.5 cursor-pointer"
                  size={20}
                />
                <RiEditCircleFill
                  onClick={() => {
                    STATE_setQuestionToEdit(q);
                    STATE_setEditQuestionToggle("disorder_questions");
                  }}
                  className="inline float-right mr-1.5 mt-0.5 cursor-pointer"
                  size={20}
                />
              </>
            ) : (
              <>
                {approvedDoc ? (
                  <BsFillPatchCheckFill
                    onClick={() => FUNC_disapproveQuestion(q, state)}
                    className="inline float-right mr-6 mt-0.5 cursor-pointer"
                    size={20}
                  />
                ) : (
                  <BsPatchCheck
                    onClick={() => FUNC_approveQuestion(q, state)}
                    className="inline float-right mr-6 mt-0.5 cursor-pointer"
                    size={20}
                  />
                )}
              </>
            )}
          </div>
          <div className="ml-6 mr-2 mt-2 text-left text-[15px]">
            {q.question.slice(0, 111) != q.question
              ? q.question.slice(0, 100).trim() + "..."
              : q.question}
            <BsInfoCircle
              className="inline ml-2 -mt-0.5 text-off cursor-pointer"
              size={14}
              onClick={() =>
                Swal.fire(
                  "Supportive Meaning",
                  q.meaning
                    ? q.meaning
                    : "This question has no predefined supportive meaning."
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }

  function question(q, chainId, positionId) {
    let approvedDoc = q.approvals.find((doc) => doc.did == state.doctor.did);
    return (
      <div className="ml-3 mt-2 mr-10 mb-6">
        {q.approvals && STATE_viewType == "central" && (
          <div className="bg-dark bg-opacity-80 mx-auto h-[1.2rem] text-center w-[11rem] rounded-t-xl p-1">
            {q.approvals.slice(0, 5).map((approvalDoc, i) => {
              return (
                <>
                  <img
                    key={i}
                    src={approvalDoc.photo}
                    className="inline-block m-0.5 w-6 h-6 rounded-full"
                  />
                </>
              );
            })}
            {q.approvals.length > 5 && (
              <div className="inline bg-light rounded-full ml-0.5 py-0.5 px-1 text-[10px] text-dark border border-dark">
                +{q.approvals.length - 5}
              </div>
            )}
          </div>
        )}
        <div className="w-[20rem] h-[10rem] mb-2 px-6 py-4 rounded-full bg-light bg-opacity-70 text-dark text-opacity-75">
          <div className="ml-3">
            <div className="inline-block text-xs bg-med rounded-full text-dark ml-3 mr-2 px-2 py-1">
              Q.{q.code}
            </div>
            {q.freeflow && q.targeted && (
              <div className="ml-2 bg-black rounded-full h-2 w-7 inline-block"></div>
            )}
            {q.freeflow && !q.targeted && (
              <div className="ml-2 bg-off rounded-full h-2 w-7 inline-block"></div>
            )}
            {!q.freeflow && (
              <div className="ml-2 bg-med bg-opacity-80 rounded-full h-2 w-7 inline-block"></div>
            )}
            {q.duration && (
              <div className="ml-2 bg-dark bg-opacity-80 rounded-full h-2 w-7 inline-block"></div>
            )}
            {q.mandatory && (
              <div className="ml-2 bg-darker bg-opacity-60 rounded-full h-2 w-7 inline-block"></div>
            )}
            {STATE_viewType == "individual" ? (
              <>
                <AiFillDelete
                  onClick={() => FUNC_deleteQuestion(q, chainId, state)}
                  className="inline float-right mr-6 mt-0.5 cursor-pointer"
                  size={20}
                />
                <RiEditCircleFill
                  onClick={() => {
                    STATE_setQuestionToEdit(q);
                    STATE_setEditQuestionToggle("symptom_questions");
                  }}
                  className="inline float-right mr-1.5 mt-0.5 cursor-pointer"
                  size={20}
                />
              </>
            ) : (
              <>
                {approvedDoc ? (
                  <BsFillPatchCheckFill
                    onClick={() => FUNC_disapproveQuestion(q, state)}
                    className="inline float-right mr-6 mt-0.5 cursor-pointer"
                    size={20}
                  />
                ) : (
                  <BsPatchCheck
                    onClick={() => FUNC_approveQuestion(q, state)}
                    className="inline float-right mr-6 mt-0.5 cursor-pointer"
                    size={20}
                  />
                )}
              </>
            )}
          </div>
          <div className="ml-6 mr-2 mt-2 text-left text-[15px]">
            {q.question.slice(0, 111) != q.question
              ? q.question.slice(0, 100).trim() + "..."
              : q.question}
            <BsInfoCircle
              className="inline ml-2 -mt-0.5 text-off cursor-pointer"
              size={14}
              onClick={() =>
                Swal.fire(
                  "Supportive Meaning",
                  q.meaning
                    ? q.meaning
                    : "This question has no predefined supportive meaning."
                )
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center w-[20rem]">
          {Object.keys(q.options).map((op, i) => {
            return (
              <div
                key={i}
                className={
                  STATE_highlightedOption[0] == chainId &&
                  STATE_highlightedOption[1] == positionId &&
                  STATE_highlightedOption[2] == String(q.code) &&
                  STATE_highlightedOption[3] == op
                    ? "bg-opacity-50 border border-dark cursor-pointer bg-med text-dark text-xs rounded-full mx-1 mt-1 p-1 px-4 "
                    : "bg-opacity-20 cursor-pointer bg-med text-med text-xs rounded-full mx-1 mt-1 p-1 px-4 "
                }
                onClick={() => {
                  STATE_setHighlightedOption([
                    chainId,
                    positionId,
                    String(q.code),
                    op,
                  ]);
                  STATE_setFollowupQuestions([
                    ...STATE_followupQuestions.filter(
                      (fq) => positionId > fq[1]
                    ),
                    [chainId, positionId, String(q.code), op],
                  ]);
                }}
              >
                {op}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex">
        {/*
         *******************************************************************
         **************************** SIDEBAR ******************************
         *******************************************************************
         */}
        <div
          id="SIDEBAR"
          className="w-[28rem] text-xs p-2 text-off h-screen bg-light bg-opacity-10"
        >
          {STATE_symptomsCaught.length > 0 && (
            <div
              onClick={() => {
                STATE_setSymptomsCaught([]);
                patientInputRef.current.value = "";
              }}
              className="float-right p-3 absolute cursor-pointer"
            >
              Back
            </div>
          )}
          <div className="text-right">
            <div
              className={
                STATE_symptomModelVersion
                  ? "text-dark text-[9px]"
                  : "text-med text-[9px]"
              }
            >
              New Model?
            </div>
            <Switch
              checked={STATE_symptomModelVersion}
              onChange={() => {
                STATE_setSymptomModelVersion(!STATE_symptomModelVersion);
                FUNC_toolSymptomsCaught(
                  STATE_setSymptomsCaught,
                  STATE_symptomModelVersion,
                  patientInputRef
                );
              }}
              className={`${STATE_symptomModelVersion ? "bg-dark" : "bg-light"}
          relative inline-flex h-[15px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  STATE_symptomModelVersion
                    ? "translate-x-[39px]"
                    : "translate-x-0"
                }
            pointer-events-none inline-block h-[11px] w-[11px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className="text-center text-off">
            <img
              src="/images/App/main_logo.png"
              alt=""
              className="w-16 mx-auto"
            />
            <input
              ref={patientInputRef}
              placeholder="type input..."
              className="font-semibold text-sm p-2 mt-4 mb-2 mr-2 text-center rounded-md bg-off bg-opacity-30"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  FUNC_toolSymptomsCaught(
                    STATE_setSymptomsCaught,
                    STATE_symptomModelVersion,
                    patientInputRef
                  );
                }
              }}
            />
            {loadedRef ? (
              <span
                onClick={() =>
                  FUNC_toolSymptomsCaught(
                    STATE_setSymptomsCaught,
                    STATE_symptomModelVersion,
                    patientInputRef
                  )
                }
                className="text-lg cursor-pointer"
              >
                &#x21A9;
              </span>
            ) : (
              <FaSpinner
                className="animate-spin inline-block -mt-1"
                size={20}
              />
            )}
          </div>
          <div className="max-h-[75vh] overflow-y-auto text-center justify-center p-4">
            {STATE_allDisorders &&
              STATE_allDisorders.sort((p1, p2) =>
                p2.priority < p1.priority
                  ? 1
                  : p2.priority > p1.priority
                  ? -1
                  : 0
              ).map((d, i) => {
                if (
                  (!STATE_selectedDisorder || STATE_selectedDisorder == d) &&
                  STATE_symptomsCaught.length == 0
                ) {
                  return (
                    <>
                      <div className="flex" key={i}>
                        <div>
                          <div className="h-[1.6rem] w-8 pt-[0.3rem] rounded-full bg-dark bg-opacity-80 text-center mt-2 text-white text-[16px] ">
                            {d.priority}
                          </div>
                          <div className="flex text-center mt-1 ml-0.5">
                            <BsFillArrowDownCircleFill
                              className="mr-0.5 text-off cursor-pointer"
                              size={14}
                              onClick={() => {
                                STATE_setSelectedDisorder(null);
                                FUNC_changeDisorderPriority(
                                  state,
                                  d,
                                  1,
                                  Math.max(
                                    ...STATE_allDisorders.map((d) => d.priority)
                                  )
                                );
                              }}
                            />
                            <BsFillArrowUpCircleFill
                              className="text-black cursor-pointer"
                              size={14}
                              onClick={() => {
                                STATE_setSelectedDisorder(null);
                                FUNC_changeDisorderPriority(
                                  state,
                                  d,
                                  -1,
                                  Math.max(
                                    ...STATE_allDisorders.map((d) => d.priority)
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div
                          key={i}
                          onClick={() => {
                            if (STATE_selectedDisorder == d) {
                              STATE_setSelectedDisorder(null);
                              STATE_setSelectedSymptom(null);
                            } else {
                              STATE_setSelectedDisorder(d);
                            }
                          }}
                          className={`${
                            d.name == "Non-Disorder Affiliated"
                              ? STATE_selectedDisorder == d
                                ? `text-white bg-gradient-to-r from-off to-pink-500`
                                : `bg-gray-200 text-gray-500`
                              : STATE_selectedDisorder == d
                              ? `text-white bg-gradient-to-r from-off to-pink-500`
                              : `bg-light text-dark`
                          } cursor-pointer text-[14px] bg-opacity-80 font-normal rounded-xl px-5 py-4 m-1.5 ml-5 w-full`}
                        >
                          {d.name}
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
          <div className="text-center justify-center flex p-3 flex-wrap overflow-y-auto max-h-[63vh] -mt-5">
            {STATE_symptomsCaught.length > 0
              ? STATE_symptomsCaught.map((s, i) => {
                  return i == 0 && STATE_symptomsCaught.length == 1 ? (
                    <div>No symptoms detected</div>
                  ) : (
                    i != 0 && (
                      <div
                        key={i}
                        onClick={() => {
                          STATE_selectedSymptom == s.symId
                            ? STATE_setSelectedSymptom(null)
                            : STATE_setSelectedSymptom(s.symId);
                        }}
                        className={`${
                          STATE_selectedSymptom == s.symId &&
                          "bg-opacity-40 border-t-4 border-light"
                        } cursor-pointer w-full px-3 py-2 text-[12px] bg-darker bg-opacity-60 text-white rounded-xl font-medium m-1`}
                      >
                        {s.shortDesc}
                      </div>
                    )
                  );
                })
              : STATE_allSymptoms &&
                STATE_allSymptoms.map((s, i) => {
                  if (STATE_selectedDisorder) {
                    if (
                      s.disorders.includes(STATE_selectedDisorder.disId) ||
                      (s.disorders.length == 0 &&
                        STATE_selectedDisorder.disId == "nda")
                    ) {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            STATE_selectedSymptom == s.symId
                              ? STATE_setSelectedSymptom(null)
                              : STATE_setSelectedSymptom(s.symId);
                          }}
                          className={`${
                            STATE_selectedSymptom == s.symId &&
                            "bg-opacity-40 border-t-4 border-light"
                          } cursor-pointer w-full px-3 py-2 text-[12px] bg-darker bg-opacity-60 text-white rounded-xl font-medium m-1`}
                        >
                          {s.shortDesc}
                        </div>
                      );
                    }
                  }
                })}
          </div>
        </div>
        {/*
         *******************************************************************
         ************************** MAIN CHAINS ****************************
         *******************************************************************
         */}
        <div id="MAINCHAINS" className="w-screen h-screen ml-8 overflow-x-auto">
          <div className="flex ml-2">
            {state && (
              <span className="m-2 mt-4">
                <select
                  className={`cursor-pointer font-medium focus:ring-0 focus:border-med
                   rounded-lg px-4 py-2 text-xs ${
                     STATE_viewType == "central"
                       ? `text-white border-med bg-med`
                       : `text-med border-med`
                   } arrow-none`}
                  value={STATE_viewType}
                  onChange={(e) => {
                    STATE_setViewType(e.target.value);
                  }}
                >
                  <option value="individual">
                    Dr. {state.doctor.name}'s View
                  </option>
                  <option value="central">Brightlight Central View</option>
                </select>
              </span>
            )}
            {state && (
              <div className="absolute top-2.5 z-[60] right-[72px] cursor-pointer">
                <div className="bg-dark drop-shadow bg-opacity-80 rounded-full p-1.5 pr-4 flex">
                  <img
                    src={state.doctor.photo}
                    alt=""
                    className="inline w-[38px] h-[38px] rounded-full"
                  />
                  <div className="text-white ml-2 text-xs font-medium leading-[12px]">
                    Dr. {state.doctor.name}
                    <br />
                    <span className="text-[8px] font-regular">
                      {state.doctor.accreditations}
                    </span>
                    <br />
                    <span className="text-[9px] font-light">
                      {state.doctor.title}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div
              className="absolute top-4 z-[60] right-4 bg-white drop-shadow-lg opacity-75 p-1.5 rounded-full cursor-pointer"
              onClick={logoutUser}
            >
              <img
                src="/images/App/login.png"
                alt=""
                className="w-6 opacity-75"
              />
            </div>
          </div>
          <div className="flex ml-2">
            <span className="text-black flex mt-6 mb-6 ml-2 text-xs font-medium">
              <div className="mt-1 bg-black rounded-full h-2 w-7 inline-block"></div>
              <div className="ml-2 float-left">
                Free Flow
                <br />
                (targeted)
              </div>
            </span>
            <span className="text-off flex mt-6 mb-6 ml-6 text-xs font-medium">
              <div className="mt-1 bg-off rounded-full h-2 w-7 inline-block"></div>
              <div className="ml-2 float-left">
                Free Flow
                <br />
                (untargeted)
              </div>
            </span>
            <span className="text-med text-opacity-80 flex mt-6 mb-6 ml-6 text-xs font-medium">
              <div className="mt-1 bg-med bg-opacity-80 rounded-full h-2 w-7 inline-block"></div>{" "}
              <div className="ml-2 float-left">Multi Choice</div>
            </span>
            <span className="text-dark text-opacity-80 flex mt-6 mb-6 ml-6 text-xs font-medium">
              <div className="mt-1 bg-dark bg-opacity-80 rounded-full h-2 w-7 inline-block"></div>
              <div className="ml-2 float-left">Duration</div>
            </span>
            <span className="text-darker text-opacity-75 flex mt-6 mb-6 ml-6 text-xs font-medium">
              <div className="mt-1 bg-darker bg-opacity-75 rounded-full h-2 w-7 inline-block"></div>
              <div className="ml-2 float-left">Mandatory</div>
            </span>
          </div>

          {/********************************* SYMPTOM MODULE *********************************/}

          <div id="SYMPTOMMODULE1" className="flex mb-1">
            {STATE_queriedDisorderQuestions.length > 0 &&
              STATE_queriedDisorderQuestions.map((question, i) => {
                return (
                  <>
                    <div key={i} className="">
                      {disorderQuestion(question)}
                    </div>
                  </>
                );
              })}
            {STATE_viewType == "individual" && (
              <div
                className={STATE_selectedSymptom ? "mt-8 ml-5 pr-12" : "hidden"}
              >
                <div
                  className="cursor-pointer align-middle bg-gradient-to-b from-off to-pink-500 p-2 py-10 text-white text-3xl rounded-full"
                  onClick={() =>
                    STATE_setAddQuestionToggle("disorder_questions")
                  }
                >
                  +
                </div>
              </div>
            )}
          </div>
          <div id="SYMPTOMMODULE2" className="flex mt-1">
            {STATE_queriedQuestions.length > 0 &&
              STATE_queriedQuestions.map((chain, i) => {
                let displayQuestion, chainId, positionId;
                return (
                  <>
                    <div className="" key={i}>
                      {Object.keys(chain).map((layer) => {
                        if (layer == 0) {
                          displayQuestion = chain[0][0][2];
                          return question(displayQuestion, chain[0][0][1], 1);
                        } else {
                          return chain[layer].map(
                            (possibleQuestionsByOption) => {
                              return STATE_followupQuestions.map((fq) => {
                                if (
                                  chain[0][0][1] == fq[0] &&
                                  possibleQuestionsByOption[0] == fq[2] &&
                                  possibleQuestionsByOption[1] == fq[3]
                                ) {
                                  displayQuestion =
                                    possibleQuestionsByOption[2];
                                  return question(
                                    displayQuestion,
                                    chain[0][0][1],
                                    layer + 1
                                  );
                                }
                              });
                            }
                          );
                        }
                      })}
                      {STATE_viewType == "individual" &&
                        STATE_highlightedOption[2] ==
                          String(
                            displayQuestion ? displayQuestion.code : ""
                          ) && (
                          <div
                            className="mb-8 ml-[9.5rem] cursor-pointer text-center align-middle bg-gradient-to-t from-dark to-pink-500 w-10 h-10 text-white text-3xl rounded-full"
                            onClick={() => {
                              STATE_setAddQuestionToggle(true);
                              STATE_setPastQInfoForAddition([
                                displayQuestion,
                                STATE_highlightedOption[3],
                              ]);
                            }}
                          >
                            +
                          </div>
                        )}
                    </div>
                  </>
                );
              })}
            {STATE_viewType == "individual" && (
              <div
                className={STATE_selectedSymptom ? "mt-8 ml-5 pr-12" : "hidden"}
              >
                <div
                  className="cursor-pointer align-middle bg-gradient-to-b from-dark to-pink-500 p-2 py-10 text-white text-3xl rounded-full"
                  onClick={() =>
                    STATE_setAddQuestionToggle("symptom_questions")
                  }
                >
                  +
                </div>
              </div>
            )}
          </div>
          {/********************************* DISORDER MODULE *********************************/}

          <div id="DISORDERMODULE" className="flex"></div>
        </div>
      </div>
      {STATE_addQuestionToggle && (
        <AddQuestionModal
          state={state}
          stateType={STATE_addQuestionToggle}
          selectedSymptom={STATE_selectedSymptom}
          allQuestions={STATE_allQuestions}
          allDisorderQuestions={STATE_allDisorderQuestions}
          allSymptoms={STATE_allSymptoms}
          pastQInfoForAddition={STATE_pastQInfoForAddition}
          stateChanger={STATE_setAddQuestionToggle}
        />
      )}
      {STATE_editQuestionToggle && (
        <EditQuestionModal
          state={state}
          stateType={STATE_editQuestionToggle}
          selectedSymptom={STATE_selectedSymptom}
          allQuestions={STATE_allQuestions}
          allSymptoms={STATE_allSymptoms}
          allDisorderQuestions={STATE_allDisorderQuestions}
          questionToEdit={STATE_questionToEdit}
          stateChanger={STATE_setEditQuestionToggle}
        />
      )}
    </>
  );
}
