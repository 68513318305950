import { nanoid } from "nanoid";
import { supplementaryCrud } from "../../../crudRequests";

export async function createDisorder(setDisorders, name, description, metadata, priority, userId, toast) {
  try {
    let payload = {
      did: nanoid(),
      name,
      description,
      metadata,
      priority,
      dateCreated: new Date(),
      associatedUsers: [userId],
    };
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "disorders",
        parameters: [payload],
        method: "insertOne",
      },
    ]);
    setDisorders((prevData) => [...prevData, payload]);
    toast({
      title: "Successfully created disorder",
      description: name + " has been added for configuration.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Failed to create disorder",
      description: err?.message || "An error occurred while creating disorder.",
      status: "error",
      isClosable: true,
    });
  }
}
