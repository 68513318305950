import { supplementaryCrud } from "../../../crudRequests";

export async function fetchQualifierQuestion(setQualifierQuestion, sid, userId, toast) {
  try {
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "qualifier_questions",
        parameters: [
          {
            sid: sid,
            associatedUsers: {
              $in: [userId],
            },
          },
        ],
        method: "findOne",
      },
    ]);
    console.log(res.data);
    setQualifierQuestion(res.data[0]);
  } catch (err) {
    toast({
      title: "Failed to fetch qualifier question data",
      description: err?.message || "An error occurred while fetching qualifier question data.",
      status: "error",
      isClosable: true,
    });
  }
}
