import { supplementaryCrud } from "../../../crudRequests";
import { nanoid } from "nanoid";
import { fetchQualifierQuestion } from "./fetchQualifierQuestion";

export async function createUpdateQualifierQuestion(e, qualifierQuestion, setQualifierQuestion, sid, userId, setIsLoading, toast) {
  try {
    setIsLoading(true);
    e.preventDefault();

    if (qualifierQuestion?._id) {
      // If the qualifier question already exists, update it
      let { _id, ...updatedQuestionContent } = qualifierQuestion;
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "qualifier_questions",
          parameters: [{ qqid: updatedQuestionContent.qqid }, { $set: updatedQuestionContent }],
          method: "updateOne",
        },
      ]);
      toast({
        title: "Successfully updated qualifier question",
        description: "Your qualifier question has been successfully updated for this symptom.",
        status: "success",
        isClosable: true,
      });
      setIsLoading(false);
    } else {
      // If the qualifier question does not exist, create it
      qualifierQuestion.qqid = nanoid();
      qualifierQuestion.sid = sid;
      qualifierQuestion.dateCreated = new Date();
      qualifierQuestion.associatedUsers = [userId];
      const res = await supplementaryCrud(null, [
        {
          db: "intake",
          collection: "qualifier_questions",
          parameters: [qualifierQuestion],
          method: "insertOne",
        },
      ]);
      toast({
        title: "Successfully created qualifier question",
        description: "A new qualifier question has been added for this symptom.",
        status: "success",
        isClosable: true,
      });
      fetchQualifierQuestion(setQualifierQuestion, sid, userId, toast);
      setIsLoading(false);
    }
  } catch (err) {
    toast({
      title: "Failed to create/update qualifier question",
      description: err?.message || "An error occurred while creating/updating qualifier question.",
      status: "error",
      isClosable: true,
    });
    setIsLoading(false);
  }
}
