import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Box,
  Grid,
  GridItem,
  InputGroup,
  Tooltip,
  IconButton,
  Select,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { FaMagic, FaNotesMedical } from "react-icons/fa";
import { DashStateContext } from "../../pages/Design";
import { autogenerateFollowupQuestion } from "./helpers/autogenerateFollowupQuestion";
import { createUpdateFollowupQuestion } from "./helpers/createUpdateFollowupQuestion";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { fetchSymptoms } from "./helpers/fetchSymptoms";
import { GrClose } from "react-icons/gr";

export default function MultipleChoice({ mcFollowup, setMCFollowup, symptom, setFollowupQuestions }) {
  const { userId } = useContext(DashStateContext);
  const [followupQuestion, setFollowupQuestion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutogenerating, setIsAutogenerating] = useState(false);
  const toast = useToast();

  const [allSymptoms, setAllSymptoms] = useState([]);
  useEffect(() => {
    fetchSymptoms(setAllSymptoms, userId, toast);
  }, []);

  useEffect(() => {
    setFollowupQuestion({});
    mcFollowup?._id ? setFollowupQuestion(mcFollowup) : setFollowupQuestion({ selectOptions: [{ name: "" }] });
  }, [mcFollowup]);

  return (
    <Modal isOpen={mcFollowup} onClose={() => setMCFollowup(null)} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create open-ended followup</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              createUpdateFollowupQuestion(
                e,
                followupQuestion,
                setFollowupQuestions,
                setFollowupQuestion,
                symptom?.sid,
                true,
                userId,
                setIsLoading,
                toast
              );
              setMCFollowup(null);
            }}
          >
            {" "}
            <FormControl id="question" isRequired>
              <FormLabel fontSize={"14px"} mb="1">
                Question
              </FormLabel>
              <Input
                type="text"
                placeholder="What is causing..."
                value={followupQuestion?.question}
                onChange={(e) => {
                  setFollowupQuestion({ ...followupQuestion, question: e.target.value });
                }}
              />
            </FormControl>
            <FormControl id="meaning" mt="5">
              <FormLabel fontSize={"14px"} mb="1">
                Helper Text (Meaning)
              </FormLabel>
              <Input
                type="text"
                placeholder="This question is saying..."
                value={followupQuestion?.meaning}
                onChange={(e) => {
                  setFollowupQuestion({ ...followupQuestion, meaning: e.target.value });
                }}
              />
            </FormControl>
            <FormControl id="meaning" isRequired mt="5">
              <FormLabel fontSize={"14px"} mb="3.5">
                Select Options
              </FormLabel>
              <Grid align="center" templateColumns="repeat(1, 1fr)" gap={4}>
                {followupQuestion?.selectOptions?.map((option, index) => (
                  <GridItem key={index}>
                    <InputGroup mt="-2">
                      <Input
                        type="text"
                        placeholder="Option"
                        mr="1"
                        value={option.name}
                        onChange={(e) => {
                          let newOptions = [...followupQuestion.selectOptions];
                          newOptions[index] = { ...newOptions[index], name: e.target.value };
                          setFollowupQuestion({ ...followupQuestion, selectOptions: newOptions });
                        }}
                      />
                      {option.symptomQualified && (
                        <Select
                          variant="flushed"
                          placeholder="Select qualifying symptom"
                          ml="1"
                          px="1"
                          border="0"
                          borderBottom="1px"
                          borderColor="gray.200"
                          onChange={(e) => {
                            let newOptions = [...followupQuestion.selectOptions];
                            newOptions[index] = { ...newOptions[index], symptomQualified: e.target.value };
                            setFollowupQuestion({ ...followupQuestion, selectOptions: newOptions });
                          }}
                        >
                          {allSymptoms
                            .filter((s) => s.sid != symptom.sid)
                            .map((s) => (
                              <option key={s.sid} value={s.sid}>
                                {s.name}
                              </option>
                            ))}
                        </Select>
                      )}
                      <Tooltip
                        label="Define whether selecting this option indicates a pre-existing symptom being qualified. This helps increase the surface area of symptom detection and prevents duplicate questioning."
                        fontSize="sm"
                      >
                        <IconButton
                          mt="1"
                          mx="1"
                          size="sm"
                          aria-label={option.symptomQualified ? "Close" : "Add"}
                          icon={option.symptomQualified ? <Icon as={GrClose} /> : <Icon as={FaNotesMedical} />}
                          onClick={() => {
                            let newOptions = [...followupQuestion.selectOptions];
                            if (option.symptomQualified) {
                              newOptions[index] = { ...newOptions[index], symptomQualified: false };
                            } else {
                              newOptions[index] = { ...newOptions[index], symptomQualified: true };
                            }
                            setFollowupQuestion({ ...followupQuestion, selectOptions: newOptions });
                          }}
                        />
                      </Tooltip>
                      <IconButton
                        mt="1"
                        size="sm"
                        aria-label="Delete option"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          let newOptions = followupQuestion.selectOptions.filter((_, i) => i !== index);
                          followupQuestion.selectOptions.length > 1
                            ? setFollowupQuestion({ ...followupQuestion, selectOptions: newOptions })
                            : toast({
                                title: "Must create at least one option",
                                description: "You must have at least one option for a multiple choice question to be appropriatel configured.",
                                status: "error",
                                isClosable: true,
                              });
                        }}
                      />
                    </InputGroup>
                  </GridItem>
                ))}
              </Grid>

              <IconButton
                mt="2"
                aria-label="Add option"
                textAlign={"left"}
                icon={<AddIcon />}
                onClick={() => {
                  setFollowupQuestion(
                    followupQuestion
                      ? {
                          ...followupQuestion,
                          selectOptions: followupQuestion.selectOptions ? [...followupQuestion.selectOptions, ""] : [" "],
                        }
                      : { selectOptions: [{ name: "" }] }
                  );
                }}
              />
            </FormControl>
            <FormControl is="allowMultiple">
              <Checkbox
                mt="6"
                isChecked={followupQuestion?.allowMultiple}
                onChange={(e) => setFollowupQuestion({ ...followupQuestion, allowMultiple: e.target.checked })}
              >
                Allow Multiple Selections
              </Checkbox>
            </FormControl>
            <Box my="3" textAlign={"center"}>
              <Button
                colorScheme="blue"
                variant="outline"
                my={4}
                mx="2"
                px="10"
                isLoading={isAutogenerating}
                onClick={() => autogenerateFollowupQuestion(followupQuestion, setFollowupQuestion, symptom, setIsAutogenerating, toast)}
              >
                <Icon as={FaMagic} mr="2" /> Autogenerate
              </Button>
              <Button colorScheme="blue" type="submit" my={4} mx="2" px="10" isLoading={isLoading}>
                Save
              </Button>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
