import axios from "axios";

const url = window.location.href.includes("localhost") ? "http://localhost:3000" : "https://webserver.brightlight.ai";

export async function promptAI(context, prompt) {
  return axios({
    method: "POST",
    url: `${url}/promptAI`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      context: context,
      prompt: prompt,
    }),
  });
}
