import { DashStateContext } from "../../pages/Design";
import { Text, Box, Badge, Grid, Tag, Button, Icon, IconButton, Spacer, Alert, AlertIcon, Flex, useToast } from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { fetchFollowupQuestions } from "./helpers/fetchFollowupQuestions";
import { HiPencilAlt } from "react-icons/hi";
import OpenEnded from "./OpenEnded";
import MultipleChoice from "./MultipleChoice";
import { DeleteIcon } from "@chakra-ui/icons";
import DeleteQuestion from "./DeleteQuestion";

export default function Followups({ symptom }) {
  const toast = useToast();
  const { userId } = useContext(DashStateContext);
  const [followupQuestions, setFollowupQuestions] = useState([]);
  const [oeFollowup, setOEFollowup] = useState(false);
  const [mcFollowup, setMCFollowup] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(false);

  useEffect(() => {
    symptom && fetchFollowupQuestions(setFollowupQuestions, symptom.sid, userId, toast);
  }, [symptom]);

  return (
    <Box textAlign={"center"}>
      <Alert colorScheme="blue" variant="left-accent" fontSize={"12px"} status="info" rounded="md" textAlign={"left"}>
        <AlertIcon />
        These questions serve to inquire and get deeper into the details of the patient's experience with this symptom. They can be used to assess
        other co-morbid symptoms and get more context around the symptom's manifestation.
      </Alert>

      <Button colorScheme="blue" variant="outline" my={4} mx="2" px="10" onClick={() => setOEFollowup(true)}>
        Create open ended followup
      </Button>
      <Button colorScheme="blue" variant="outline" my={4} mx="2" px="10" onClick={() => setMCFollowup(true)}>
        Create multiple choice followup
      </Button>
      <OpenEnded oeFollowup={oeFollowup} setOEFollowup={setOEFollowup} symptom={symptom} setFollowupQuestions={setFollowupQuestions} />
      <MultipleChoice mcFollowup={mcFollowup} setMCFollowup={setMCFollowup} symptom={symptom} setFollowupQuestions={setFollowupQuestions} />

      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {followupQuestions
          ?.filter((fQ) => fQ.sid == symptom.sid)
          .map((fQ, index) => {
            return (
              <Box boxShadow="md" borderRadius="md" p="6" textAlign={"left"}>
                <Flex>
                  <Badge fontSize="12px" rounded="md" p="1" px="2" mb="4" colorScheme={fQ.multipleChoice ? "twitter" : "facebook"}>
                    {fQ.multipleChoice ? "Multiple Choice" : "Open Ended"}
                  </Badge>
                  <Spacer />
                  <IconButton
                    icon={<HiPencilAlt />}
                    size="sm"
                    mr="1"
                    colorScheme={fQ.multipleChoice ? "blue" : "gray"}
                    rounded="full"
                    cursor={"pointer"}
                    onClick={() => (fQ.multipleChoice ? setMCFollowup(fQ) : setOEFollowup(fQ))}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    ml="1"
                    colorScheme={fQ.multipleChoice ? "blue" : "gray"}
                    rounded="full"
                    cursor={"pointer"}
                    onClick={() => setQuestionToDelete(fQ)}
                  />
                </Flex>
                <Text fontSize={"14px"} fontWeight={"regular"}>
                  <b>Question:</b> {fQ.question}
                </Text>
                <Text fontSize={"14px"} mt="3">
                  {fQ.selectOptions?.map((option) => {
                    return (
                      <Tag mr="2" my="0.5" py="1" colorScheme="twitter" variant="outline" size="sm">
                        {option.name}
                      </Tag>
                    );
                  })}
                </Text>
              </Box>
            );
          })}
      </Grid>
      <DeleteQuestion setQuestions={setFollowupQuestions} question={questionToDelete} setQuestion={setQuestionToDelete} type={"followup"} />
    </Box>
  );
}
