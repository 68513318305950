import { nanoid } from "nanoid";
import { promptAI } from "./promptAI";

export async function autogenerateQualifierQuestion(qualifierQuestion, setQualifierQuestion, symptom, setIsAutogenerating, toast) {
  setIsAutogenerating(true);
  const autogenerationToast = toast({
    title: "Qualifier Question Autogenerating",
    description: "Please wait a few seconds for the question and it's corresponding meaning to generate.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });

  try {
    const autogeneratedQuestion = await promptAI(
      "You are a question generator",
      "Generate a question that asks a patient IF they are experiencing the following symptom (do not assume they are): '" +
        symptom.name +
        "'. Do not ask anything around duration."
    );
    const autogeneratedMeaning = await promptAI(
      "You are a helper text generator",
      "Generate an simple description of what this question is meant to ask me and provide a sample plausible response: '" +
        autogeneratedQuestion.data.output +
        "'"
    );
    setQualifierQuestion({
      ...qualifierQuestion,
      question: autogeneratedQuestion.data.output.replace(/^['"]|['"]$/g, ""),
      meaning: autogeneratedMeaning.data.output.replace(/^['"]|['"]$/g, ""),
      applicableButtonText: "Yes",
      unapplicableButtonText: "No",
    });

    setIsAutogenerating(false);

    toast({
      title: "Qualifier Question Autogenerated",
      description: "A qualifier question has been successfully autogenerated for this symptom.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    setIsAutogenerating(false);
    toast({
      title: "Qualifier Question Not Autogenerated",
      description: err?.message || "An error occurred.",
      status: "error",
      isClosable: true,
    });
  }

  toast.close(autogenerationToast);
}
