import { DashStateContext } from "../../pages/Design";
import {
  Text,
  Box,
  Icon,
  IconButton,
  Grid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  Checkbox,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { fetchDurationQuestion } from "./helpers/fetchDurationQuestion";
import { createUpdateDurationQuestion } from "./helpers/createUpdateDurationQuestion";
import { autogenerateDurationQuestion } from "./helpers/autogenerateDurationQuestion";
import { FaMagic } from "react-icons/fa";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import DeleteQuestion from "./DeleteQuestion";

export default function Duration({ symptom }) {
  const toast = useToast();
  const { userId } = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutogenerating, setIsAutogenerating] = useState(false);
  const [durationQuestion, setDurationQuestion] = useState();
  const [questionToDelete, setQuestionToDelete] = useState(false);

  useEffect(() => {
    symptom && fetchDurationQuestion(setDurationQuestion, symptom.sid, userId, toast);
  }, [symptom]);

  useEffect(() => {
    !durationQuestion?.selectOptions?.length && setDurationQuestion({ selectOptions: [""] });
  }, [durationQuestion]);

  return (
    <form onSubmit={(e) => createUpdateDurationQuestion(e, durationQuestion, setDurationQuestion, symptom.sid, userId, setIsLoading, toast)}>
      <Box textAlign={"center"}>
        <Alert colorScheme="blue" variant="left-accent" fontSize={"12px"} status="info" rounded="md" textAlign={"left"}>
          <AlertIcon />
          This question probes the duration of the symptom's presence. It is applicable only when a follow-up inquiry regarding the extent of the
          symptom's manifestation is essential for tangible assessment.
        </Alert>

        <FormControl id="question" isRequired mt="5">
          <FormLabel fontSize={"14px"} mb="1">
            Question
          </FormLabel>
          <Input
            type="text"
            placeholder="Have you been..."
            value={durationQuestion?.question || ""}
            onChange={(e) => {
              setDurationQuestion({ ...durationQuestion, question: e.target.value });
            }}
          />
        </FormControl>

        <FormControl id="meaning" mt="5">
          <FormLabel fontSize={"14px"} mb="1">
            Helper Text (Meaning)
          </FormLabel>
          <Input
            type="text"
            placeholder="This question is saying..."
            value={durationQuestion?.meaning || ""}
            onChange={(e) => {
              setDurationQuestion({ ...durationQuestion, meaning: e.target.value });
            }}
          />
        </FormControl>

        <FormControl id="meaning" isRequired mt="5">
          <FormLabel fontSize={"14px"} mb="1">
            Length/Time Select Options
          </FormLabel>
          <Grid align="center" templateColumns="repeat(4, 1fr)" gap={4}>
            {durationQuestion?.selectOptions?.map((option, index) => (
              <GridItem key={index}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Option"
                    value={option}
                    onChange={(e) => {
                      let newOptions = [...durationQuestion.selectOptions];
                      newOptions[index] = e.target.value;
                      setDurationQuestion({ ...durationQuestion, selectOptions: newOptions });
                    }}
                  />
                  <InputRightElement
                    children={
                      <IconButton
                        size="sm"
                        aria-label="Delete option"
                        icon={<DeleteIcon />}
                        onClick={() => {
                          let newOptions = durationQuestion.selectOptions.filter((_, i) => i !== index);
                          durationQuestion.selectOptions.length > 1
                            ? setDurationQuestion({ ...durationQuestion, selectOptions: newOptions })
                            : toast({
                                title: "Must create at least one option",
                                description: "You must have at least one option for a duration question to be appropriatel configured.",
                                status: "error",
                                isClosable: true,
                              });
                        }}
                      />
                    }
                  />
                </InputGroup>
              </GridItem>
            ))}
            <GridItem textAlign={"left"}>
              <IconButton
                aria-label="Add option"
                textAlign={"left"}
                icon={<AddIcon />}
                onClick={() => {
                  setDurationQuestion(
                    durationQuestion
                      ? {
                          ...durationQuestion,
                          selectOptions: durationQuestion.selectOptions ? [...durationQuestion.selectOptions, ""] : [" "],
                        }
                      : { selectOptions: [""] }
                  );
                }}
              />
            </GridItem>
          </Grid>
        </FormControl>

        <Box my="3">
          <Button
            colorScheme="blue"
            variant="outline"
            my={4}
            mx="2"
            px="10"
            isLoading={isAutogenerating}
            onClick={() => autogenerateDurationQuestion(durationQuestion, setDurationQuestion, symptom, setIsAutogenerating, toast)}
          >
            <Icon as={FaMagic} mr="2" /> Autogenerate
          </Button>
          <Button colorScheme="blue" type="submit" my={4} mx="2" px="10" isLoading={isLoading}>
            Save
          </Button>
          {durationQuestion?._id && (
            <>
              <br />
              <Button colorScheme="blue" variant="ghost" mx="2" px="10" onClick={() => setQuestionToDelete(durationQuestion)}>
                Delete
              </Button>
            </>
          )}
        </Box>
      </Box>
      <DeleteQuestion setQuestions={setDurationQuestion} question={questionToDelete} setQuestion={setQuestionToDelete} type={"duration"} />
    </form>
  );
}
