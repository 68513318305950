import { nanoid } from "nanoid";
import { promptAI } from "./promptAI";

export async function autogenerateDurationQuestion(durationQuestion, setDurationQuestion, symptom, setIsAutogenerating, toast) {
  setIsAutogenerating(true);
  const autogenerationToast = toast({
    title: "Duration Question Autogenerating",
    description: "Please wait a few seconds for the question and it's corresponding meaning to generate.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });

  try {
    const autogeneratedQuestion = await promptAI(
      "You are a question generator",
      "Generate a question that asks a patient how long they have been experiencing the following symptom: '" + symptom.name + "'."
    );
    const autogeneratedMeaning = await promptAI(
      "You are a helper text generator",
      "Generate an simple description of what this question is meant to ask me and provide a sample plausible response: '" +
        autogeneratedQuestion.data.output +
        "'"
    );
    setDurationQuestion({
      ...durationQuestion,
      question: autogeneratedQuestion.data.output.replace(/^['"]|['"]$/g, ""),
      meaning: autogeneratedMeaning.data.output.replace(/^['"]|['"]$/g, ""),
      selectOptions: ["Less than a week", "1-2 weeks", "2-4 weeks", "1-3 months", "3-6 months", "6-12 months", "More than a year"],
    });

    setIsAutogenerating(false);

    toast({
      title: "Duration Question Autogenerated",
      description: "A duration question has been successfully autogenerated for this symptom.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    setIsAutogenerating(false);
    toast({
      title: "Duration Question Not Autogenerated",
      description: err?.message || "An error occurred.",
      status: "error",
      isClosable: true,
    });
  }

  toast.close(autogenerationToast);
}
