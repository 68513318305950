import axios from "axios";
import { lightFormat } from "date-fns";
const url = window.location.href.includes("localhost") ? "http://localhost:3000" : "https://webserver.brightlight.ai";

export function jwtDecode(t) {
  try {
    return JSON.parse(window.atob(t.split(".")[1]));
  } catch {
    window.location.href = "../login";
  }
}

//////////

export async function supplementaryCrud(state, requestObjs) {
  return axios({
    method: "POST",
    url: `${url}/supplementaryCrud`,
    headers: {
      "Content-Type": "application/json",
      //Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      //id: state?.jwt ? jwtDecode(state.jwt).id : "",
      requestObjs: requestObjs,
    }),
  })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("client error", error.message);
      //if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    });
}

export async function crud(state, requestObjs, options, signal) {
  let configService = {
    method: "POST",
    url: `${url}/crud`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + state?.jwt || "",
    },
    data: JSON.stringify({
      id: state?.jwt ? jwtDecode(state.jwt).id : "",
      requestObjs: requestObjs,
      options,
    }),
    signal,
  };
  return axios(configService)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      console.log("client error", error.message);
      //if (error.code !== "ERR_CANCELED") window.location.href = "../login";
    });
}

export async function loadDataForDoctorLogin(state, navigate, loader, signal) {
  loader && loader("animate-spin");
  try {
    let location = state.selectedLocation || state.doctor.lid?.[0];
    let oid = state.doctor?.oid;
    let did = state.doctor?.did;

    let pParameters = state.doctor?.access?.allPatients
      ? [
          {
            $or: [{ lid: location }, { lid: "" }, { lid: { $size: 0 } }],
          },
          {
            pid: 1,
            lid: 1,
            fName: 1,
            lName: 1,
            email: 1,
            phone: 1,
            did: 1,
            stpCustomerId: 1,
            healthCard: 1,
            dob: 1,
          },
        ]
      : [
          {
            $or: [
              { lid: location, did },
              { lid: "", did },
              { lid: { $size: 0 }, did },
            ],
          },
        ];

    let requestObjs = [
      {
        db: state.db || "blh",
        collection: "patients",
        parameters: pParameters,
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "appointments",
        parameters: [{ lid: location, did }],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "services",
        parameters: [{ lid: location }],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "notifications",
        parameters: [{ userType: "doctor", [did]: { $exists: true } }, { [did]: 1 }],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "doctors",
        parameters: [{ email: state.doctor?.email }],
        method: "findOne",
      },
      {
        db: state.db || "blh",
        collection: "locations",
        parameters: [{ lid: { $in: state.doctor?.lid } }],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "insurers",
        parameters: [{}],
        method: "find",
      },
      {
        db: state.db || "blh",
        collection: "serviceCriteria",
        parameters: [{ lid: location }],
        method: "find",
      },
    ];
    let res = await crud(state, requestObjs, null, signal);
    let data = res.data;
    loader && loader("");

    navigate(state.destination, {
      state: {
        ...state,
        patients: data[0] || [],
        appointments: data[1] || [],
        services: data[2] || [],
        notifications: data[3] ? data[3][did] : [],
        doctor: data[4],
        selectedLocation: location,
        locations: data[5] || [],
        insurers: data[6] || [],
        serviceCriteria: data[7] || [],
      },
    });
  } catch (error) {
    console.log(error.message);
    window.location.href = "../login";
  }
}
export async function loadDataForAdminLogin(state, navigate, loader, signal) {
  loader && loader("animate-spin");

  try {
    let location = state.selectedLocation || state.admin.location?.[0];
    let oid = state.admin?.oid;
    let userId = location + oid;

    let requestObjs = [
      {
        db: "blh",
        collection: "doctors",
        parameters: [{ lid: location, oid }],
        method: "find",
      },
      {
        db: "blh",
        collection: "patients",
        parameters: [
          {
            $or: [
              { lid: location, oid },
              { oid, lid: "" },
              { oid, lid: { $size: 0 } },
            ],
          },
        ],
        method: "find",
      },
      {
        db: "blh",
        collection: "appointments",
        parameters: [{ lid: location, oid }],
        method: "find",
      },
      {
        db: "blh",
        collection: "services",
        parameters: [{ serviceOid: oid }],
        method: "find",
      },
      {
        db: "blh",
        collection: "notifications",
        parameters: [{ userType: "admin", [userId]: { $exists: true } }, { [userId]: 1 }],
        method: "findOne",
      },
      {
        db: "blh",
        collection: "admins",
        parameters: [{ email: state.admin?.email }],
        method: "findOne",
      },
      {
        db: "blh",
        collection: "locations",
        parameters: [{ lid: { $in: state.admin?.location || [] } }],
        method: "find",
      },
    ];
    //console.log(location)
    let res = await crud(state, requestObjs, null, signal);
    let data = res.data;
    loader && loader("");
    //console.log(data[1])
    navigate(state.destination, {
      state: {
        ...state,
        doctors: data[0] || [],
        patients: data[1] || [],
        appointments: data[2] || [],
        services: data[3] || [],
        notifications: data[4] ? data[4][userId] : [],
        admin: data[5],
        selectedLocation: location,
        locations: data[6] || [],
      },
    });
  } catch (error) {
    console.log(error.message);
    if (error.code !== "ERR_CANCELED") window.location.href = "../login";
  }
}

export async function viewOrganizationByUrl(u) {
  let configOrganization = {
    method: "POST",
    url: `${url}/viewOrganizationByUrl`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ url: u }),
  };
  return axios(configOrganization)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      window.location.href = "../login";
    });
}

export async function viewCustomers() {
  let config = {
    method: "GET",
    url: `${url}/viewCustomers`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config);
}
