import { DashStateContext } from "../../pages/Design";
import {
  Text,
  Box,
  Select,
  Switch,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  Flex,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useContext, useState } from "react";
import { updateSymptom } from "./helpers/updateSymptom";

export default function Risk({ symptom, setSymptoms }) {
  const toast = useToast();
  const { userId } = useContext(DashStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [riskLevel, setRiskLevel] = useState(symptom?.riskLevel);
  const [shAssessment, setShAssessment] = useState(symptom?.shAssessment);
  const [terminateIntake, setTerminateIntake] = useState(symptom?.terminateIntake);
  const [redirect, setRedirect] = useState(symptom?.redirect);
  const [redirectUrl, setRedirectUrl] = useState(symptom?.redirectUrl);

  const updateRiskCriteria = (e) => {
    e.preventDefault();
    symptom.riskLevel = riskLevel;
    symptom.shAssessment = shAssessment;
    symptom.terminateIntake = terminateIntake;
    symptom.redirect = redirect;
    symptom.redirectUrl = redirectUrl;
    updateSymptom(setSymptoms, symptom, toast, setIsLoading);
  };

  return (
    <form onSubmit={updateRiskCriteria}>
      <Box textAlign={"center"}>
        <Alert colorScheme="blue" variant="left-accent" fontSize={"12px"} status="info" rounded="md" textAlign={"left"}>
          <AlertIcon />
          This setting allows you to configure the risk level of the symptom. This is used to determine the overall risk of a patient, as well as
          configure automatic alerts, triggers and routing.
        </Alert>

        <FormControl id="question" isRequired mt="5">
          <FormLabel fontSize={"14px"} mb="1">
            Risk Level
          </FormLabel>
          <Select
            variant="flushed"
            type="text"
            placeholder="-"
            border="0"
            borderBottom="1px"
            borderColor="gray.200"
            value={riskLevel}
            onChange={(e) => setRiskLevel(e.target.value)}
          >
            <option value={"1"}>1 (Very Low Risk)</option>
            <option value={"2"}>2 (Low Risk)</option>
            <option value={"3"}>3 (Intermediate Risk)</option>
            <option value={"4"}>4 (High Risk)</option>
            <option value={"5"}>5 (Very High Risk)</option>
          </Select>
        </FormControl>

        <FormControl display="flex" alignItems="center" my="6">
          <FormLabel htmlFor="shAssessment" mb="0" color="blue.500">
            Assess Suicide/Homicide Risk
          </FormLabel>
          <Switch size="lg" id="shAssessment" isChecked={shAssessment} onChange={(e) => setShAssessment(e.target.checked)} />
        </FormControl>

        <FormControl display="flex" alignItems="center" my="6" mb="8">
          <FormLabel htmlFor="terminateIntake" mb="0" color="red.500">
            Terminate Intake
          </FormLabel>
          <Switch
            size="lg"
            colorScheme={"red"}
            id="terminateIntake"
            isChecked={terminateIntake}
            onChange={(e) => setTerminateIntake(e.target.checked)}
          />
        </FormControl>

        <Box as="span" display="flex">
          <FormControl is="redirect">
            <Checkbox isChecked={redirect} onChange={(e) => setRedirect(e.target.checked)}>
              Redirect
            </Checkbox>
          </FormControl>

          <FormControl ml="4" mt="-1.5">
            <InputGroup>
              <InputLeftElement ml="2" fontSize="12px">
                URL
              </InputLeftElement>
              <Input
                pl="12"
                type="text"
                placeholder="https://talksuicide.ca"
                isDisabled={!redirect}
                value={redirectUrl}
                onChange={(e) => setRedirectUrl(e.target.value)}
              />
            </InputGroup>
          </FormControl>
        </Box>

        <Box my="3">
          <Button colorScheme="blue" type="submit" my={4} mx="2" px="10" isLoading={isLoading}>
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
}
