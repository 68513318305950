import React, { useState, useEffect, useContext } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Icon, Input, Button, IconButton, useToast } from "@chakra-ui/react";
import { formatDateTime } from "./helpers/timeFormatters";
import { fetchDisorders } from "./helpers/fetchDisorders";
import { fetchSymptoms } from "./helpers/fetchSymptoms";
import { updateDisorder } from "./helpers/updateDisorder";
import { DashStateContext } from "../../pages/Design";
import CreateDisorder from "./CreateDisorder";
import { AiOutlineSetting } from "react-icons/ai";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import DeleteDisorder from "./DeleteDisorder";
import Symptoms from "./Symptoms";
import { renderEditableField } from "./helpers/renderEditableField";

export default function Disorders() {
  const { userId } = useContext(DashStateContext);
  const [disorders, setDisorders] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [editStatus, setEditStatus] = useState({});
  const [currentEdit, setCurrentEdit] = useState({});
  const [expandedRow, setExpandedRow] = useState(null);

  const toast = useToast();

  useEffect(() => {
    fetchDisorders(setDisorders, userId, toast);
    fetchSymptoms(setSymptoms, userId, toast);
  }, []);

  const expandRow = (index) => {
    !editStatus[index] && setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <Box textAlign={"center"}>
      <Box overflowX="auto" m="8" p="4" boxShadow={"md"}>
        <Table variant="unstyled">
          <TableCaption>
            <Icon as={AiOutlineSetting} fontSize={"20px"} mt="-0.5" /> Click on a disorder to configure it
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Priority</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Metadata</Th>
              <Th>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {disorders
              .sort((a, b) => a.priority - b.priority)
              .map((disorder, index) => (
                <>
                  <Box h="2"></Box>
                  <Tr key={index} style={{ width: "100%" }} bg="gray.50">
                    <Td w="10px" onClick={() => expandRow(index)} cursor="pointer">
                      {renderEditableField(editStatus, currentEdit, setCurrentEdit, index, "priority", disorder.priority, "number")}
                    </Td>
                    <Td w="24vh" onClick={() => expandRow(index)} cursor="pointer">
                      {renderEditableField(editStatus, currentEdit, setCurrentEdit, index, "name", disorder.name, "text")}
                    </Td>
                    <Td w="44vh" onClick={() => expandRow(index)} cursor="pointer">
                      {renderEditableField(editStatus, currentEdit, setCurrentEdit, index, "description", disorder.description, "text", 20)}
                    </Td>
                    <Td w="20vh" onClick={() => expandRow(index)} cursor="pointer">
                      {renderEditableField(editStatus, currentEdit, setCurrentEdit, index, "metadata", disorder.metadata, "text", 20)}
                    </Td>
                    <Td w="16vh" onClick={() => expandRow(index)} cursor="pointer">
                      {formatDateTime(disorder.dateCreated, true)}
                    </Td>
                    <Td w="16vh">
                      {editStatus[index] ? (
                        <IconButton
                          aria-label="Confirm edit"
                          icon={<CheckIcon />}
                          size="sm"
                          m="1"
                          onClick={() => {
                            {
                              updateDisorder(setDisorders, index, currentEdit[index], toast);
                              setEditStatus({ ...editStatus, [index]: false });
                            }
                          }}
                        />
                      ) : (
                        <IconButton
                          aria-label="Edit disorder"
                          icon={<EditIcon />}
                          size="sm"
                          m="1"
                          onClick={() => {
                            {
                              setEditStatus({ ...editStatus, [index]: true });
                              setCurrentEdit({
                                ...currentEdit,
                                [index]: { ...disorder, dateCreated: formatDateTime(disorder.dateCreated, true) },
                              });
                            }
                          }}
                        />
                      )}
                      <DeleteDisorder setDisorders={setDisorders} disorder={disorder} />
                    </Td>
                  </Tr>
                  {expandedRow === index && (
                    <Symptoms
                      allSymptoms={symptoms}
                      symptoms={symptoms.filter((s) => s.associatedDisorders.includes(disorder.did))}
                      setSymptoms={setSymptoms}
                      disorder={disorder}
                    />
                  )}
                </>
              ))}
          </Tbody>
        </Table>
      </Box>
      <CreateDisorder setDisorders={setDisorders} />
    </Box>
  );
}
