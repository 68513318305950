import { render } from "@testing-library/react";
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Symptoms from "../components/Chains.Symptoms";

export default function Chains(props) {
  const loadedRef = useRef(null);
  const loaderRef = useRef(null);

  const initialSymptom = useRef(null);

  const [nextLoader, setNextLoader] = useState(false);

  const [symptomsToggle, setSymptomsToggle] = useState(false);

  const [warning, setWarning] = useState("");
  const [info, setInfo] = useState("");

  const [searchInitialSymptom, setSearchInitialSymptom] = useState("|");
  const [totalSymptoms, setTotalSymptoms] = useState([]);
  const [interactionSymptoms, setInteractionSymptoms] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);
  const [allSymptomCategories, setAllSymptomCategories] = useState([]);
  const [instanceID, setInstanceID] = useState("TBA");
  const [originalSymptomID, setOriginalSymptomID] = useState();
  const [originalSymptomDescription, setOriginalSymptomDescription] = useState();
  const [lastQ, setLastQ] = useState();
  const [lastA, setLastA] = useState();
  const [latestQuestion, setLatestQuestion] = useState();
  const [chatbotConversation, setChatbotConversation] = useState([]);
  const [searchQuestions, setSearchQuestions] = useState([]);
  const [symptomsCaught, setSymptomsCaught] = useState([]);

  const chatEndRef = useRef(null);
  const chosenQuestion = useRef(null);
  const newQuestion = useRef(null);
  const editedQuestion = useRef(null);

  const [questionEdited, setQuestionEdited] = useState(false);
  const [presetResponse, setPresetResponse] = useState(false);
  const [mplOrOpen, setMplOrOpen] = useState(true);
  const [symptarg, setSymptarg] = useState(true);
  const [identified, setIdentified] = useState(true);
  const [durationresp, setDurationresp] = useState(false);
  const [mandresp, setMandresp] = useState(false);

  const [selectedOption, setSelectedOption] = useState(1);
  const [optionsCounter, setOptionsCounter] = useState(2);
  const options = useRef(null);
  const optionsEndRef = useRef(null);
  const option1Value = useRef(null);
  const option2Value = useRef(null);
  const option3Value = useRef(null);
  const option4Value = useRef(null);
  const option5Value = useRef(null);
  const option6Value = useRef(null);
  const option7Value = useRef(null);
  const acknowledgement1Value = useRef(null);
  const acknowledgement2Value = useRef(null);
  const acknowledgement3Value = useRef(null);
  const acknowledgement4Value = useRef(null);
  const acknowledgement5Value = useRef(null);
  const acknowledgement6Value = useRef(null);
  const acknowledgement7Value = useRef(null);

  const targetedSymptom = useRef(null);
  const positiveAcknowledgement = useRef(null);
  const negativeAcknowledgement = useRef(null);

  function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function initializeQuestions() {
    let config = {
      method: "GET",
      url: "https://webserver.brightlight.ai/viewInjectedQuestions",
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        console.log("viewInjectedQuestions success: ");
        console.log(response.data);
        const questions = [];
        for (let i = 0; i < response.data.length; i++) {
          questions.push({
            id: response.data[i].id,
            code: response.data[i].code,
            value: "C." + response.data[i].id.slice(-3).toUpperCase() + " - " + response.data[i].question,
            pathways: response.data[i].pathways,
            relevantSymptoms: response.data[i].relevantSymptoms,
            duration: response.data[i].duration,
            mandatory: response.data[i].mandatory,
            freeflow: response.data[i].freeflow,
            targeted: response.data[i].targeted,
            options: response.data[i].options,
          });
        }
        setAllQuestions(questions);
        //if (totalSymptoms.length > 1 && mplOrOpen) updateRelevantSymptoms(questions);
        toolGetInjection(questions, instanceID, totalSymptoms, chatbotConversation);
      })
      .catch(function (error) {
        console.log("viewInjectedQuestions error: ");
        console.log(error);
        console.log("retrying: ");
        initializeQuestions();
      });
  }

  function initialize() {
    loaderRef.current.className = "inline-block";
    loadedRef.current.className = "hidden";
    //populate symptoms
    let config = {
      method: "GET",
      url: "https://webserver.brightlight.ai/viewSymptoms",
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        console.log("viewSymptoms success: ");
        console.log(response.data);
        setAllSymptoms(response.data);
        setAllSymptomCategories(
          response.data
            .map((e, key) => {
              return e["Topics"];
            })
            .flat(1)
            .filter(function (item, i, ar) {
              return ar.indexOf(item) === i;
            })
        );
        setLastQ("What can I help you with today?");
        setLastA("—");
        loaderRef.current.className = "hidden";
        loadedRef.current.className = "inline";
      })
      .catch(function (error) {
        console.log("viewSymptoms error: ");
        console.log(error);
        console.log("retrying: ");
        initialize();
      });
    //populate questions
    initializeQuestions();
  }

  function generateRandString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  function startChain(code) {
    let id, random_symptom;
    allSymptoms.map((s) => {
      if (s.Code == code) {
        random_symptom = s;
      }
    });
    id = "I." + ("0" + random_symptom.Code).slice(-2) + "." + generateRandString(3);
    setInstanceID(id);
    setOriginalSymptomID("S." + ("0" + random_symptom.Code).slice(-2));
    setOriginalSymptomDescription(random_symptom.Description);
    toolGetInjection(allQuestions, id, [random_symptom.Code], "");
    setTotalSymptoms([random_symptom.Code]);
  }

  function addOption() {
    if (optionsCounter != 7) {
      setOptionsCounter(optionsCounter + 1);
    } else {
      setWarning("7 is the maximum number of options allowed per question.");
    }
  }

  /*function updateRelevantSymptoms(questions) {
    let c, relevantSymptoms, pastRelevantSymptoms, pastRequiredSymptoms;
    questions.map((q) => {
      if (q.value == latestQuestion) {
        c = q.code;
        pastRelevantSymptoms = q.relevantSymptoms;
        pastRequiredSymptoms = q.requiredSymptoms.split("|");
      }
    });
    if (pastRelevantSymptoms) {
      relevantSymptoms = pastRelevantSymptoms.concat(interactionSymptoms).filter(function (item, i, ar) {
        return ar.indexOf(item) === i;
      });
    } else {
      relevantSymptoms = interactionSymptoms;
    }
    for (let i = 0; i < pastRequiredSymptoms.length; i++) {
      let symptoms = pastRequiredSymptoms[i].substring(pastRequiredSymptoms[i].indexOf("(") + 1, pastRequiredSymptoms[i].lastIndexOf(")")).split(",");
      for (let j = 0; j < symptoms.length; j++) {
        let index = relevantSymptoms.indexOf(symptoms[j]);
        index > -1 && relevantSymptoms.splice(index, 1);
      }
    }
    let config = {
      method: "POST",
      url: "https://webserver.brightlight.ai/updateInjectedQuestionByCode",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        updateBody: {
          code: parseInt(c),
          relevantSymptoms: relevantSymptoms,
        },
      }),
    };
    axios(config)
      .then(function (response) {
        console.log("updateInjectedQuestionByCode success: ");
        console.log(response.data);
      })
      .catch(function (error) {
        console.log("updateInjectedQuestionByCode error: ");
        console.log(error);
      });
  }*/

  function toolSymptomsCaught(doctorResponse, patientResponse) {
    setLastQ(doctorResponse);
    setLastA(patientResponse);
    setChatbotConversation((prevCC) => [...prevCC, [doctorResponse, patientResponse]]);

    let config = {
      method: "GET",
      url: "https://chatbotserver.brightlight.ai/toolSymptomsCaught" + "?doctorResponse=" + doctorResponse + "&patientResponse=" + patientResponse,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        console.log("toolSymptomsCaught success: ");
        for (let i = 0; i < allSymptoms.length; i++) {
          for (let j = 0; j < response.data.length; j++) {
            if (response.data[j] == parseInt(allSymptoms[i].Code)) {
              setSymptomsCaught((prevSC) => [...prevSC, allSymptoms[i].Code]);
            }
          }
        }
        chosenQuestion.current.value = "-";
        newQuestion.current.value = null;
        setSymptomsToggle(true);
      })
      .catch(function (error) {
        console.log("toolSymptomsCaught error: ");
        console.log(error);
        console.log("retrying: ");
        toolSymptomsCaught();
      });
  }

  function toolGetInjection(questions, instanceName, prioritySymptoms, priorityInjections) {
    priorityInjections =
      Object.prototype.toString.call(priorityInjections) === "[object Array]"
        ? priorityInjections
            .map((qa) => {
              for (let i = 0; i < questions.length; i++) {
                if (qa[0] == questions[i].value.split(" - ")[1]) {
                  return questions[i].code + "*" + qa[1];
                }
              }
            })
            .join("|")
        : "";
    let config = {
      method: "GET",
      url:
        "https://chatbotserver.brightlight.ai/toolGetInjection" +
        "?instanceName=" +
        instanceName +
        "&prioritySymptoms=" +
        prioritySymptoms.join("|") +
        "&priorityInjections=" +
        priorityInjections,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        console.log("toolGetInjection success: ");
        console.log(response.data);
        if (response.data != "DONE") {
          chosenQuestion.current.value = "C." + response.data.Code.slice(-3).toUpperCase() + " - " + response.data.Question;
          newQuestion.current.value = "";
          editedQuestion.current.value = "";
          setPresetResponse(true);
          populateConfig();
        } else {
          setPresetResponse(false);
          populateConfig();
        }
      })
      .catch(function (error) {
        console.log("toolGetInjection error: ");
        console.log(error);
      });
  }

  function addQuestionToDB(mainQuestion, options) {
    const id = makeid(10);
    let c;
    if (questionEdited) {
      allQuestions.map((q) => {
        if (q.value == chosenQuestion.current.value) {
          c = q.code;
        }
      });
    } else {
      c =
        Math.max.apply(
          null,
          allQuestions.map((q) => parseInt(q.code))
        ) + 1;
    }
    setLatestQuestion("C." + id.slice(-3).toUpperCase() + " - " + mainQuestion);
    let totalInjections = chatbotConversation
      .map((qa) => {
        for (let i = 0; i < allQuestions.length; i++) {
          if (qa[0] == allQuestions[i].value.split(" - ")[1]) {
            if (!allQuestions[i].freeflow) {
              return "{" + allQuestions[i].code + "*" + qa[1] + "}";
            } else {
              return "{" + allQuestions[i].code + "*" + qa[1] + "}";
            }
          }
        }
      })
      .join(",");
    let uniqueSymptoms = totalSymptoms.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
    console.log("uniqueSymptoms: " + uniqueSymptoms.join(","));
    console.log("totalSymptoms: " + totalSymptoms.join(","));
    let requiredSymptoms = "(" + uniqueSymptoms.join(",") + ")=" + uniqueSymptoms.length;
    let requiredInjections = chatbotConversation.length != 0 ? "```(" + totalInjections + ")=" + chatbotConversation.length : "";
    let relevantSymptoms = [];
    if (!mplOrOpen && symptarg) relevantSymptoms = [targetedSymptom.current.value.split("[S.")[1].split("]")[0]];
    let addBody = {
      id: id,
      code: parseInt(c),
      pathways: requiredSymptoms + requiredInjections,
      question: mainQuestion,
      options: options,
      freeflow: !mplOrOpen,
      targeted: symptarg,
      duration: durationresp,
      mandatory: false,
      relevantSymptoms: relevantSymptoms,
    };
    let config = {
      method: "POST",
      url: "https://webserver.brightlight.ai/addInjectedQuestion",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ addBody: addBody }),
    };
    axios(config)
      .then(function (response) {
        console.log("addInjectedQuestion success: ");
        console.log(response.data);
        initializeQuestions(instanceID, totalSymptoms, chatbotConversation);
        if (!mplOrOpen && !symptarg) window.location.reload();
      })
      .catch(function (error) {
        console.log("addInjectedQuestion error: ");
        console.log(error);
      });
  }
  function findAndUpdateQuestionInDB() {
    let id, pastPathways;
    allQuestions.map((q) => {
      if (q.value == chosenQuestion.current.value) {
        id = q.id;
        pastPathways = q.pathways;
      }
    });
    let uniqueSymptoms = totalSymptoms.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
    let pathways = pastPathways + "|(" + uniqueSymptoms.join(",") + ")=" + uniqueSymptoms.length;
    pathways = pathways
      .split("|")
      .filter(function (item, i, ar) {
        return ar.indexOf(item) === i;
      })
      .join("|");
    setLatestQuestion(chosenQuestion.current.value);
    let totalInjections = chatbotConversation
      .map((qa) => {
        for (let i = 0; i < allQuestions.length; i++) {
          if (qa[0] == allQuestions[i].value.split(" - ")[1]) {
            return "{" + allQuestions[i].code + "*" + qa[1] + "}";
          }
        }
      })
      .join(",");
    let newRequiredInjections = chatbotConversation.length != 0 ? "```(" + totalInjections + ")=" + chatbotConversation.length : "";
    let config = {
      method: "POST",
      url: "https://webserver.brightlight.ai/updateInjectedQuestionById",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        updateBody: {
          id: id,
          pathways: pathways + newRequiredInjections,
        },
      }),
    };
    axios(config)
      .then(function (response) {
        console.log("updateInjectedQuestionById success: ");
        console.log(response.data);
        if (!mplOrOpen && !symptarg) window.location.reload();
      })
      .catch(function (error) {
        console.log("updateInjectedQuestionById error: ");
        console.log(error);
      });
  }

  function resetMplChoice() {
    setOptionsCounter(2);
    chosenQuestion.current.value = "-";
    option1Value.current.value = "";
    acknowledgement1Value.current.value = "";
    option2Value.current.value = "";
    acknowledgement2Value.current.value = "";
    option3Value.current.value = "";
    acknowledgement3Value.current.value = "";
    option4Value.current.value = "";
    acknowledgement4Value.current.value = "";
    option5Value.current.value = "";
    acknowledgement5Value.current.value = "";
    option6Value.current.value = "";
    acknowledgement6Value.current.value = "";
    option7Value.current.value = "";
    acknowledgement7Value.current.value = "";
  }

  function next() {
    setNextLoader(true);
    setWarning("");
    if (instanceID == "TBA") {
      setWarning("Please select an initial symptom to proceed.");
      setNextLoader(false);
    } else {
      if (chosenQuestion.current.value == "-" && newQuestion.current.value == "") {
        setWarning("Please choose or create a question to proceed.");
        setNextLoader(false);
      } else {
        let mainQuestion, randomResponse;
        if (chosenQuestion.current.value == "-") {
          mainQuestion = newQuestion.current.value;
        } else {
          if (questionEdited && editedQuestion.current.value != "") {
            mainQuestion = editedQuestion.current.value;
          } else {
            mainQuestion = chosenQuestion.current.value.split(" - ")[1];
          }
        }
        if (mplOrOpen == true) {
          if (option1Value.current.value == "" || option2Value.current.value == "") {
            setWarning("Please ensure you have created at least 2 options per multiple choice question.");
            setNextLoader(false);
          } else {
            const options = {};
            if (optionsCounter >= 1 && option1Value.current.value != "") options[option1Value.current.value] = acknowledgement1Value.current.value;
            if (optionsCounter >= 2 && option2Value.current.value != "") options[option2Value.current.value] = acknowledgement2Value.current.value;
            if (optionsCounter >= 3 && option3Value.current.value != "") options[option3Value.current.value] = acknowledgement3Value.current.value;
            if (optionsCounter >= 4 && option4Value.current.value != "") options[option4Value.current.value] = acknowledgement4Value.current.value;
            if (optionsCounter >= 5 && option5Value.current.value != "") options[option5Value.current.value] = acknowledgement5Value.current.value;
            if (optionsCounter >= 6 && option6Value.current.value != "") options[option6Value.current.value] = acknowledgement6Value.current.value;
            if (optionsCounter >= 7 && option7Value.current.value != "") options[option7Value.current.value] = acknowledgement7Value.current.value;
            randomResponse = Object.keys(options)[selectedOption - 1];
            questionEdited || newQuestion.current.value != "" ? addQuestionToDB(mainQuestion, options) : findAndUpdateQuestionInDB();
            resetMplChoice();
            toolSymptomsCaught(mainQuestion, randomResponse);
          }
        } else {
          if (symptarg == true) {
            randomResponse = identified ? "Presented" : "Unpresented";
            if (targetedSymptom.current.value != "") {
              const options = {};
              options["Presented"] = positiveAcknowledgement.current.value;
              options["Unpresented"] = negativeAcknowledgement.current.value;
              setLastQ(mainQuestion);
              setLastA(randomResponse);
              setChatbotConversation((prevCC) => [...prevCC, [mainQuestion, randomResponse]]);
              randomResponse == "Presented" &&
                setTotalSymptoms((prevS) => prevS.concat([targetedSymptom.current.value.split("[S.")[1].split("]")[0]]));
              questionEdited || newQuestion.current.value != "" ? addQuestionToDB(mainQuestion, options) : findAndUpdateQuestionInDB();
              setNextLoader(false);
            } else {
              setWarning("Please select a symptom to target for your symptom targeted open-ended question to proceed.");
              setNextLoader(false);
            }
          } else {
            questionEdited || newQuestion.current.value != "" ? addQuestionToDB(mainQuestion, {}) : findAndUpdateQuestionInDB();
          }
        }
      }
    }
  }

  function populateConfig() {
    if (chosenQuestion.current.value == "-") {
      setQuestionEdited(false);
      setMplOrOpen(true);
      setSelectedOption(1);
      resetMplChoice();
      setSymptarg(true);
      setDurationresp(false);
      setMandresp(false);
      setIdentified(true);
      targetedSymptom.current.value = "";
    } else {
      setSelectedOption(1);
      setIdentified(true);
      setQuestionEdited(false);
      allQuestions.map((q) => {
        if (q.value == chosenQuestion.current.value) {
          setMplOrOpen(!q.freeflow);
          if (q.freeflow == false) {
            //to remove warning of ending simulation
            setSymptarg(true);
            setOptionsCounter(Object.keys(q.options).length);
            if (Object.keys(q.options)[0]) {
              option1Value.current.value = Object.keys(q.options)[0];
              acknowledgement1Value.current.value = q.options[Object.keys(q.options)[0]];
            }
            if (Object.keys(q.options)[1]) {
              option2Value.current.value = Object.keys(q.options)[1];
              acknowledgement2Value.current.value = q.options[Object.keys(q.options)[1]];
            }
            if (Object.keys(q.options)[2]) {
              option3Value.current.value = Object.keys(q.options)[2];
              acknowledgement3Value.current.value = q.options[Object.keys(q.options)[2]];
            }
            if (Object.keys(q.options)[3]) {
              option4Value.current.value = Object.keys(q.options)[3];
              acknowledgement4Value.current.value = q.options[Object.keys(q.options)[3]];
            }
            if (Object.keys(q.options)[4]) {
              option5Value.current.value = Object.keys(q.options)[4];
              acknowledgement5Value.current.value = q.options[Object.keys(q.options)[4]];
            }
            if (Object.keys(q.options)[5]) {
              option6Value.current.value = Object.keys(q.options)[5];
              acknowledgement6Value.current.value = q.options[Object.keys(q.options)[5]];
            }
            if (Object.keys(q.options)[6]) {
              option7Value.current.value = Object.keys(q.options)[6];
              acknowledgement7Value.current.value = q.options[Object.keys(q.options)[6]];
            }
          } else {
            setSymptarg(q.targeted);
            allSymptoms.map((sym) => {
              if (sym.Code == q.relevantSymptoms) {
                targetedSymptom.current.value = "[S." + sym.Code + "] " + sym.Description;
              }
            });
            positiveAcknowledgement.current.value = q.options["Presented"];
            negativeAcknowledgement.current.value = q.options["Unpresented"];
          }
          setDurationresp(q.duration);
          setMandresp(q.mandatory);
        }
      });
    }
  }

  function editQuestion() {
    if (!questionEdited) {
      if (chosenQuestion.current.value == "-") {
        setWarning("Please select an existing question to edit it & it's configuration variables.");
      } else {
        setWarning("");
        editedQuestion.current.value = "";
        setQuestionEdited(true);
        setInfo("");
      }
    } else {
      editedQuestion.current.value = "";
      setQuestionEdited(false);
      populateConfig();
    }
  }

  function selectSymptom(q) {
    startChain(initialSymptom.current.value.split("[S.")[1].split("]")[0]);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function selectQuestion(q) {
    chosenQuestion.current.value = q.replaceAll("<mark>", "").replaceAll("</mark>", "");
    newQuestion.current.value = null;
    setSearchQuestions([]);
    populateConfig();
  }

  function searchQ(s) {
    if (s.length == 0) {
      setSearchQuestions([]);
    } else {
      resetMplChoice();
      setSearchQuestions([]);
      for (let q = 0; q < allQuestions.length; q++) {
        if (allQuestions[q].value.toLowerCase().includes(s)) {
          if (allQuestions[q].value.replaceAll(s, "<mark>" + s + "</mark>").includes("<mark>")) {
            setSearchQuestions((prevSQ) => [...prevSQ, allQuestions[q].value.replaceAll(s, "<mark>" + s + "</mark>")]);
          } else {
            setSearchQuestions((prevSQ) => [
              ...prevSQ,
              allQuestions[q].value.replaceAll(capitalizeFirstLetter(s), "<mark>" + capitalizeFirstLetter(s) + "</mark>"),
            ]);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (instanceID != "TBA") {
      if (symptomsToggle == false) {
        initializeQuestions(instanceID, totalSymptoms, chatbotConversation);
      }
    }
  }, [symptomsToggle]);

  useEffect(() => {
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, [lastA]);

  useEffect(() => {
    optionsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [optionsCounter]);

  useEffect(() => {
    symptarg == false ? setInfo("Configuration of a non-targeted free flow response will end this simulation.") : setInfo("");
  }, [symptarg]);

  useEffect(() => {
    if (durationresp == true) {
      setMplOrOpen(true);
      setSelectedOption(1);
      resetMplChoice();
      setSymptarg(true);
      setDurationresp(true);
      setIdentified(true);
      targetedSymptom.current.value = "";
      setOptionsCounter(4);
      option1Value.current.value = "<1mo.";
      acknowledgement1Value.current.value = "Sorry to hear you've had to go through this.";
      option2Value.current.value = "1-3mo.";
      acknowledgement2Value.current.value = "Sorry to hear you've had to go through this.";
      option3Value.current.value = "3-6mo.";
      acknowledgement3Value.current.value = "Sorry to hear you've had to go through this.";
      option4Value.current.value = "6-12mo.";
      acknowledgement4Value.current.value = "Sorry to hear you've had to go through this for so long.";
      option4Value.current.value = ">1yr.";
      acknowledgement4Value.current.value = "Sorry to hear you've had to go through this for so long.";
    } else {
      resetMplChoice();
    }
  }, [durationresp]);

  useEffect(() => initialize(), []);

  return (
    <>
      <div id="no_phone" className="hidden text-med m-7 bg-light bg-opacity-50 p-7 rounded-md font-medium text-xl w-screen">
        Please make sure to access the intake design app on a larger screen such as a laptop or desktop for the best experience.
      </div>
      <div className="w-screen h-screen flex">
        <div className="p-8 pt-12 h-screen w-[24rem]">
          <img src="/images/App/main_logo.png" className="h-20" />
          <div className="mt-28 mb-24 p-4 text-[65px] leading-[5rem] font-semibold">
            <span className="text-light">Intake</span>
            <br />
            <span className="text-med">Design</span>
            <br />
            <span className="text-dark">App</span>
          </div>
          <div className="m-6">
            <span className="text-light text-sm">powered by</span>
            <img src="/images/logo.png" className="h-10 m-5" />
          </div>
        </div>

        <div className="bg-light bg-opacity-25 w-full h-screen min-w-[62.5rem] min-h-[45rem]">
          <div className="rounded-sm bg-med bg-opacity-25 h-[90vh] m-9 mr-12 ml-12 p-8 border-t-8 border-dark min-h-[41rem]">
            <div ref={loaderRef} className="hidden">
              <img src="/images/App/loader.gif" className="h-36" />
            </div>
            <div ref={loadedRef}>
              <div className="text-white font-medium text-3xl drop-shadow -mt-1">
                <span className="flex">
                  <span className="font-bold text-4xl">
                    <span className="font-medium mr-1">Simulation Chain </span>
                    <span>{instanceID}</span>
                  </span>
                  <span className="flex text-sm -mt-1 ml-auto mr-1">
                    <img src="/images/App/doc_img.jpg" className="h-12 inline-block rounded-full drop-shadow pl-3 pr-1 -pt-1" />{" "}
                    <span className="pt-0.5">
                      <span className="text-dark text-opacity-30">by</span>
                      <br />
                      <span className="text-dark text-opacity-75">Dr. Phillips</span>
                      <br />
                    </span>
                  </span>
                </span>
                {info != "" && (
                  <div className="absolute right-1 text-xs bg-blue-300 p-3 mt-4 w-48">
                    <div className="font-bold flex">
                      <span>
                        <img src="/images/App/warn.png" className="h-4 mb-0.5 mr-0.5 inline-block" /> Info:
                      </span>
                      <span className="ml-auto mr-2 underline cursor-pointer font-light" onClick={() => setInfo("")}>
                        x
                      </span>
                    </div>
                    <div className="font-regular mt-1">{info}</div>
                  </div>
                )}
                {warning != "" && (
                  <div className="absolute right-1 text-xs bg-yellow-500 p-3 mt-4 w-48">
                    <div className="font-bold flex">
                      <span>
                        <img src="/images/App/warn.png" className="h-4 mb-0.5 mr-0.5 inline-block" /> Warning:
                      </span>
                      <span className="ml-auto mr-2 underline cursor-pointer font-light" onClick={() => setWarning("")}>
                        x
                      </span>
                    </div>
                    <div className="font-regular mt-1">{warning}</div>
                  </div>
                )}
              </div>
              <div className="mt-3 min-w-[52.5rem] font-medium text-lg bg-gradient-to-r from-pink-500 to-dark p-2 pl-4 pr-4 rounded-md leading-5">
                {!originalSymptomID ? (
                  <>
                    <div className="text-white mb-1 flex">
                      <input
                        className="text-md rounded-md bg-transparent border border-background mt-1 pl-3 w-[28.75rem] p-2.5 text-background placeholder-white"
                        ref={initialSymptom}
                        placeholder="choose a symptom"
                        onFocus={() => {
                          setSearchInitialSymptom(initialSymptom.current.value);
                        }}
                        onChange={() => {
                          setSearchInitialSymptom(initialSymptom.current.value);
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setSearchInitialSymptom("|");
                          }, 500);
                        }}
                      />
                      <button className="bg-pink-500 mx-5 px-2 rounded-md mt-1" onClick={selectSymptom}>
                        <img src="/images/App/go.png" className="h-6" />
                      </button>
                    </div>
                    <div className={searchInitialSymptom == "|" ? "hidden" : ""}>
                      <div className="absolute z-10 -ml-4 w-[29.75rem] h-[14.75rem] mt-3 p-3 bg-gradient-to-r from-dark to-pink-500 rounded-md text-background text-sm overflow-auto">
                        {allSymptomCategories.map((cat) => {
                          let returnObj = [];
                          for (let sym = 0; sym < allSymptoms.length; sym++) {
                            if (allSymptoms[sym]["Topics"].indexOf(cat) != -1) {
                              let idplusdesc = "[S." + allSymptoms[sym].Code + "] " + allSymptoms[sym].Description;
                              returnObj.push(
                                <>
                                  {idplusdesc.includes(searchInitialSymptom) && (
                                    <>
                                      {!returnObj.length && searchInitialSymptom == "" && (
                                        <>
                                          {allSymptomCategories.indexOf(cat) == 0 && <div className="-mt-1.5"></div>}
                                          <div className="py-2.5 ml-1 opacity-90 font-medium">{cat}</div>
                                        </>
                                      )}
                                      <div
                                        className="leading-4 py-1.5 ml-4 mr-2 cursor-pointer font-light"
                                        onClick={() => {
                                          initialSymptom.current.value = idplusdesc;
                                        }}
                                      >
                                        [S.{allSymptoms[sym].Code}] {allSymptoms[sym].Description}
                                      </div>
                                    </>
                                  )}
                                </>
                              );
                            }
                          }
                          return returnObj;
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-background font-light text-[12px] mt-0.5 mb-0.5">initial problem [{originalSymptomID}]</div>
                    <div className="text-white mb-1.5 flex">{originalSymptomDescription}</div>
                  </>
                )}
              </div>
              <div className="mt-5 font-medium text-lg">
                <span className="text-off text-opacity-50">
                  last <span className="text-off text-xl ml-[0.05rem]">Q.</span>:
                </span>{" "}
                <span className="text-dark text-xl">{lastQ}</span> <br />
                <span className="text-off text-opacity-50">
                  last <span className="text-off text-xl">A.</span>:
                </span>{" "}
                <span className="text-dark text-opacity-75 text-xl">{lastA}</span>
              </div>
              <div className="mt-2 text-2xl text-dark font-medium w-[36rem] text-opacity-75"></div>
              <div className="mt-4 font-medium text-lg">
                <div className="flex">
                  <span className="text-off text-opacity-50 mr-2">what would you ask them?</span>
                  <span
                    className="text-off text-sm pt-0.5 cursor-pointer"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    (...nothing further?&nbsp;
                    <img src="/images/App/end.png" className="h-7 ml-1 inline-block" /> <span className="text-red-600"> simulation</span>)
                  </span>
                </div>
              </div>
              <div className="mt-3 font-medium text-lg">
                <div className="flex">
                  <div className="flex">
                    <img src="/images/App/chat_logo.png" className="h-8 mt-0.5 drop-shadow" />
                    <div className="ml-4">
                      <select
                        className="dropdown rounded-md bg-med bg-opacity-75 border-none text-background w-[26.75rem]"
                        ref={chosenQuestion}
                        onChange={populateConfig}
                        disabled={newQuestion.current ? (newQuestion.current.value != "" ? true : false) : false}
                      >
                        <option value="-">
                          {newQuestion.current
                            ? newQuestion.current.value != ""
                              ? "clear the new question to select a question"
                              : "choose a question"
                            : "choose a question"}
                        </option>
                        {allQuestions.map((e, key) => {
                          return (
                            <option key={key} value={e.value}>
                              {e.value}
                            </option>
                          );
                        })}
                      </select>
                      <br />
                      <div className={presetResponse ? "font-semibold leading-4 mt-1 mb-3.5" : "hidden"} style={{ fontSize: "9px" }}>
                        <img src="/images/App/info_icon.png" className="w-4 inline-block -mt-0.5 cursor-pointer" />
                        &nbsp;{" "}
                        <span className="text-off font-medium cursor-pointer">
                          This question is preset to appear in this context. You may <b>accept it as is, edit it or create new</b>.
                        </span>
                      </div>{" "}
                      <img src="/images/App/select_choices.png" className={!presetResponse ? "h-6 w-72 mt-0.5 mb-2 ml-16" : "hidden"} />
                      <div
                        className={
                          questionEdited
                            ? "mt-0.5 font-regular text-sm rounded-md bg-dark bg-opacity-75 border-none text-background w-[26.75rem]"
                            : "hidden"
                        }
                      >
                        <span className="pl-[0.9rem] px-[0.08rem] text-lg">-</span>{" "}
                        <input
                          className="p-[0.33rem] pl-3 ml-[0.26rem] rounded-md w-[24.75rem] text-off"
                          placeholder="EDIT question text (blank value will assume original text)"
                          ref={editedQuestion}
                        />
                      </div>
                      <div
                        className={
                          !questionEdited
                            ? "mt-0.5 font-regular text-sm rounded-md bg-dark bg-opacity-75 border-none text-background w-[26.75rem]"
                            : "hidden"
                        }
                      >
                        <span className="pl-3 text-lg">+</span>{" "}
                        <input
                          className="p-[0.33rem] pl-3 ml-[0.26rem] rounded-md w-[24.75rem] text-off"
                          placeholder="OR create one..."
                          ref={newQuestion}
                          onChange={() => searchQ(newQuestion.current.value.toLowerCase())}
                          onBlur={() => {
                            setTimeout(() => {
                              setSearchQuestions([]);
                            }, 500);
                          }}
                        />
                      </div>
                      <div className="-mt-7">
                        {searchQuestions.map((q, i) => {
                          return (
                            <div onClick={() => selectQuestion(q)}>
                              <div
                                className={
                                  "z-10 cursor-pointer absolute bg-background rounded-md text-med px-2 py-[0.44rem] ml-8 mt-[" +
                                  (i + 1) * 2 +
                                  "rem] text-xs"
                                }
                                dangerouslySetInnerHTML={{ __html: q.split(" - ")[1] }}
                              ></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <img
                    src={!questionEdited ? "/images/App/edit.png" : "/images/App/back.png"}
                    className="h-16 ml-5 drop-shadow inline-block cursor-pointer"
                    onClick={editQuestion}
                  />
                  <div className="-mt-12 ml-8 w-[17rem] z-50">
                    <div className="bg-light p-4 pt-3 rounded-md">
                      <h3 className="text-sm text-background p-1 rounded-md bg-gradient-to-r from-dark to-light">&nbsp; Chain History</h3>
                      <div className="h-[7.5rem] overflow-y-scroll">
                        {chatbotConversation.length != 0 ? (
                          chatbotConversation.map(function (cc, i) {
                            return (
                              <div className="past_interactions" key={i}>
                                <div className="mt-3">
                                  <div className="flex mr-12 ml-1">
                                    <img src="/images/App/chat_logo.png" className="h-5 mt-0.5 mr-3 drop-shadow inline-block" />
                                    <div className="inline-block py-2 px-3 bg-med bg-opacity-50 text-background rounded-md text-left chat_q">
                                      {cc[0]}
                                    </div>
                                  </div>
                                  <div className="flex ml-24 mr-1">
                                    <div className="py-2 px-3 bg-med text-background rounded-md text-right ml-auto inline-block mt-2 mb-2 chat_a">
                                      {cc[1]}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="mt-4 px-2 text-center text-xs text-med">
                            Start by configuring a question to see a record of the simulation chain here.
                          </div>
                        )}
                        <div ref={chatEndRef} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="-mt-1 font-medium text-lg">
                <span className="text-off text-opacity-50">configure details → </span>
              </div>
              <div className="mt-3 font-medium text-lg">
                <div className="flex">
                  <div
                    onClick={() => {
                      chosenQuestion.current.value != "-" && !questionEdited
                        ? setInfo(
                            "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                          )
                        : setMplOrOpen(true);
                    }}
                    className={
                      mplOrOpen == true
                        ? "rounded-tl-md rounded-bl-md bg-dark text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                        : "rounded-tl-md rounded-bl-md bg-med bg-opacity-50 text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                    }
                  >
                    multiple choice
                  </div>
                  <div
                    onClick={() => {
                      chosenQuestion.current.value != "-" && !questionEdited
                        ? setInfo(
                            "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                          )
                        : setMplOrOpen(false);
                    }}
                    className={
                      mplOrOpen == false
                        ? "rounded-tr-md rounded-br-md bg-dark text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                        : "rounded-tr-md rounded-br-md bg-med bg-opacity-50 text-sm pt-2 pb-2 pl-4 pr-4 text-background cursor-pointer"
                    }
                  >
                    open ended
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div
                  className={mplOrOpen == true ? "flex float-left mt-4 mr-6 pb-1 overflow-auto w-[26.5rem]" : "hidden"}
                  ref={options}
                  onClick={() => {
                    chosenQuestion.current.value != "-" && !questionEdited
                      ? setInfo("You can only configure fields for an existing question by going into edit mode for a variant to be orderly created.")
                      : setInfo("");
                  }}
                >
                  <div>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 1
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(1);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option1Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement1Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <div className={optionsCounter >= 2 ? "pl-5" : "hidden"}>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 2
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(2);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option2Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement2Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <div className={optionsCounter >= 3 ? "pl-5" : "hidden"}>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 3
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(3);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option3Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement3Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <div className={optionsCounter >= 4 ? "pl-5" : "hidden"}>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 4
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(4);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option4Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement4Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <div className={optionsCounter >= 5 ? "pl-5" : "hidden"}>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 5
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(5);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option5Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement5Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <div className={optionsCounter >= 6 ? "pl-5" : "hidden"}>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 6
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(6);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option6Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement6Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <div className={optionsCounter == 7 ? "pl-5" : "hidden"}>
                    <div className="flex">
                      <div
                        className={
                          selectedOption == 7
                            ? "border border-4 border-dark w-7 h-7 bg-dark rounded-full cursor-pointer"
                            : "border border-4 border-dark w-7 h-7 rounded-full cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedOption(7);
                        }}
                      >
                        &nbsp;
                      </div>
                      <input
                        className="text-xs rounded-md ml-3 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="option"
                        ref={option7Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                    <div className="flex">
                      <textarea
                        className="text-xs rounded-md ml-10 h-[4.5rem] mt-2 p-1 pl-2 w-32 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={acknowledgement7Value}
                        disabled={chosenQuestion.current ? (chosenQuestion.current.value != "-" && !questionEdited ? true : false) : false}
                      />
                    </div>
                  </div>
                  <img
                    src="/images/App/plus.png"
                    className="h-8 ml-5 p-1 cursor-pointer"
                    onClick={() => {
                      chosenQuestion.current.value != "-" && !questionEdited
                        ? setInfo(
                            "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                          )
                        : addOption();
                    }}
                  />

                  <div ref={optionsEndRef} />
                </div>
                <div className={mplOrOpen != true ? "float-left mt-4 mr-5" : "hidden"}>
                  <div className="flex">
                    <div>
                      <div
                        className={
                          symptarg == true
                            ? "float-left inline-block bg-dark text-center p-5 px-6 rounded-md text-lg w-max text-background flex cursor-pointer"
                            : "bg-med bg-opacity-50 text-center p-8 px-6 rounded-md text-lg w-max text-background flex cursor-pointer"
                        }
                        onClick={() => {
                          chosenQuestion.current.value != "-" && !questionEdited
                            ? setInfo(
                                "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                              )
                            : setSymptarg(!symptarg);
                        }}
                      >
                        <img
                          src="/images/App/target.png"
                          className={symptarg == true ? "h-[2.4rem] -mt-1.5 -mb-6 pl-1" : "h-[3.5rem] -mt-2 -mb-6 pl-7"}
                        />
                        <span className={symptarg == true ? "leading-5 ml-2.5 mt-0.5 mb-1 pr-1" : "leading-6 ml-2.5 -mt-0.5 pr-7"}>
                          {symptarg == true ? (
                            <span>targeted</span>
                          ) : (
                            <span>
                              non-
                              <br />
                              targeted
                            </span>
                          )}
                        </span>
                      </div>
                      <span className="tooltip absolute w-[5rem]">
                        <div
                          className={
                            symptarg == true
                              ? identified == true
                                ? "float-left inline-block bg-dark text-center ml-1 p-3 px-[15px] rounded-md w-max text-background flex cursor-pointer"
                                : "float-left inline-block bg-med bg-opacity-50 text-center ml-1 p-3 px-[15px] rounded-md w-max text-background flex cursor-pointer"
                              : "hidden"
                          }
                          onClick={() => {
                            chosenQuestion.current.value != "-" && !questionEdited
                              ? setInfo(
                                  "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                                )
                              : setIdentified(!identified);
                          }}
                        >
                          <img src="/images/App/identified.png" className="h-[2.1rem] my-1" />
                        </div>
                        <span className="tooltiptext">Response to this question indicated the target symptom</span>
                      </span>
                      <br />
                      <input
                        className={
                          symptarg == true
                            ? "mt-[0.22rem] p-3 mb-2 rounded-md bg-dark text-xs border-none text-background w-[15.33rem] placeholder-background"
                            : "hidden"
                        }
                        ref={targetedSymptom}
                        list="symptoms"
                        placeholder="select a symptom"
                      />
                      <datalist id="symptoms">
                        {allSymptoms.map((sym) => {
                          return <option value={"[S." + sym.Code + "] " + sym.Description} />;
                        })}
                      </datalist>
                    </div>
                    <div className={symptarg == true ? "block" : "hidden"}>
                      <img src="/images/App/positive.png" className="absolute h-4 mt-1 ml-2.5" />
                      <textarea
                        className="text-xs rounded-md ml-1.5 mr-8 h-[4.14rem] p-1 pl-6 w-36 border-t-0 border-l-0 bg-white border-r-2 border-dark border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={positiveAcknowledgement}
                      />
                      <br />
                      <img src="/images/App/negative.png" className="absolute mt-0.5 h-4 ml-2.5" />
                      <textarea
                        className="text-xs rounded-md ml-1.5 mr-8 h-[2.5rem] -mt-0.5 p-1 pl-6 w-36 border-t-0 border-l-0 bg-white border-r-2 border-med border-opacity-75 border-b-2"
                        placeholder="acknowledgement"
                        ref={negativeAcknowledgement}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="w-max p-4 mt-2 text-md rounded-md text-background ">
                  <div className="flex">
                    <span className="tooltip">
                      <div
                        className={
                          durationresp == true
                            ? "bg-dark text-center p-3 rounded-md text-sm cursor-pointer mb-[0.33rem] "
                            : "bg-med bg-opacity-50 text-center p-3 rounded-md text-sm cursor-pointer mb-[0.33rem] "
                        }
                        onClick={() => {
                          chosenQuestion.current.value != "-" && !questionEdited
                            ? setInfo(
                                "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                              )
                            : setDurationresp(!durationresp);
                        }}
                      >
                        <img src="/images/App/duration.png" className="h-10 mt-0.5 ml-3" />
                        <div className="mt-1 ml-1 mr-1">
                          duration
                          <br />
                          question
                        </div>
                      </div>
                      <span className="tooltiptext">Question that is asking for a time-bound response</span>
                    </span>
                    <span className="tooltip">
                      <div
                        className={
                          mandresp == true
                            ? "bg-dark text-center p-3 rounded-md text-sm ml-5 pl-5 pr-5 cursor-pointer mb-[0.33rem]"
                            : "bg-med bg-opacity-50 text-center p-3 rounded-md text-sm ml-5 pl-5 pr-5 cursor-pointer mb-[0.33rem]"
                        }
                        onClick={() => {
                          chosenQuestion.current.value != "-" && !questionEdited
                            ? setInfo(
                                "You can only configure fields for an existing question by going into edit mode for a variant to be orderly created."
                              )
                            : setMandresp(!mandresp);
                        }}
                      >
                        <img src="/images/App/mandatory.png" className="h-8 mt-1.5 mb-2 ml-2.5" />
                        <div className="mt-1 -ml-2 -mr-2">
                          mandatory
                          <br />
                          question
                        </div>
                      </div>
                      <span className="tooltiptext">Question that must be asked given such context</span>
                    </span>

                    <div className="mt-1 ml-[6rem] cursor-pointer" onClick={next}>
                      <div className="bg-light w-[5rem] p-5 rounded-full hover:bg-med hover:bg-opacity-40">
                        <img src={nextLoader == true ? "/images/App/loader2.gif" : "/images/App/check.png"} className="h-10" />
                      </div>
                      <div className="text-dark text-opacity-50 text-md ml-6 mt-1.5">next</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {symptomsToggle && (
        <Symptoms
          symptomsCaught={symptomsCaught}
          symptomsCaughtSC={setSymptomsCaught}
          allSymptoms={allSymptoms}
          allSymptomCategories={allSymptomCategories}
          totalSymptomsSC={setTotalSymptoms}
          interactionSymptomsSC={setInteractionSymptoms}
          nextLoaderSC={setNextLoader}
          stateChanger={setSymptomsToggle}
        />
      )}
    </>
  );
}
