import { set } from "date-fns";
import { supplementaryCrud } from "../../../crudRequests";

export async function deleteQuestion(setQuestions, questionToDelete, type, toast) {
  try {
    let ids = { duration: "dqid", qualifier: "qqid", followup: "fqid" };
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: type + "_questions",
        parameters: [{ [ids[type]]: questionToDelete[ids[type]] }],
        method: "deleteOne",
      },
    ]);
    type == "followup" ? setQuestions((prev) => prev.filter((question) => question[ids[type]] !== questionToDelete[ids[type]])) : setQuestions({});
    toast({
      title: "Successfully deleted question",
      description: "The selected question and it's metadata has been deleted.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    toast({
      title: "Failed to delete question",
      description: err?.message || "An error occurred while deleting question.",
      status: "error",
      isClosable: true,
    });
  }
}
