import { supplementaryCrud } from "../../../crudRequests";

export async function updateSymptom(setSymptoms, updatedSymptom, toast, setIsLoading) {
  setIsLoading && setIsLoading(true);
  try {
    let { _id, ...updatedSymptomContent } = updatedSymptom;
    const res = await supplementaryCrud(null, [
      {
        db: "intake",
        collection: "symptoms",
        parameters: [{ sid: updatedSymptom.sid }, { $set: updatedSymptomContent }],
        method: "updateOne",
      },
    ]);
    setSymptoms((prev) => {
      return prev.map((symptom) => {
        if (symptom.sid === updatedSymptom.sid) {
          return updatedSymptom;
        } else {
          return symptom;
        }
      });
    });
    toast({
      title: "Successfully updated symptom",
      description: updatedSymptom.name + " and it's metadata has been updated.",
      status: "success",
      isClosable: true,
    });
    setIsLoading && setIsLoading(false);
  } catch (err) {
    toast({
      title: "Failed to update symptom",
      description: err?.message || "An error occurred while updating symptom.",
      status: "error",
      isClosable: true,
    });
    setIsLoading && setIsLoading(false);
  }
}
