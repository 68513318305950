import React, { useState, useEffect, useRef } from "react";
import { crud } from "../crudRequests";
import axios from "axios";
import Swal from "sweetalert2";

export function ConfigurationFunctions() {
  const [loadedRef, setLoadedRef] = useState(true);
  const [STATE_allQuestions, STATE_setAllQuestions] = useState([]);
  const [STATE_allDisorderQuestions, STATE_setAllDisorderQuestions] = useState([]);
  const [STATE_allSymptoms, STATE_setAllSymptoms] = useState([]);
  const [STATE_allDisorders, STATE_setAllDisorders] = useState([]);
  const [STATE_selectedDisorder, STATE_setSelectedDisorder] = useState(null);
  const [STATE_selectedSymptom, STATE_setSelectedSymptom] = useState(null);
  const [STATE_queriedQuestions, STATE_setQueriedQuestions] = useState([]);
  const [STATE_queriedDisorderQuestions, STATE_setQueriedDisorderQuestions] = useState([]);
  const [STATE_questionsToUpdate, STATE_setQuestionsToUpdate] = useState(null);

  async function FUNC_initializeSymptomsAndDisorders(state) {
    let res = await crud(state, [
      {
        db: "intake",
        collection: "symptoms",
        parameters: [{}],
        method: "find",
      },
      {
        db: "intake",
        collection: "disorders",
        parameters: [{}],
        method: "find",
      },
    ]);
    STATE_setAllSymptoms(res.data[0]);
    STATE_setAllDisorders(res.data[1]);
  }

  async function FUNC_initializeQuestions(state, ss, STATE_viewType) {
    let initialQuestions = await crud(state, [
      {
        db: "intake",
        collection: "symptom_questions",
        parameters: [{}],
        method: "find",
      },
      {
        db: "intake",
        collection: "disorder_questions",
        parameters: [{}],
        method: "find",
      },
    ]);
    STATE_setAllQuestions(initialQuestions.data[0]);
    STATE_setAllDisorderQuestions(initialQuestions.data[1]);
    ss && FUNC_queryQuestions(ss, initialQuestions.data[0], initialQuestions.data[1], state, STATE_viewType);
  }

  async function FUNC_changeDisorderPriority(state, disorder, direction, maxPriority) {
    !(disorder.priority == 0 && direction == -1) &&
      !(disorder.priority == maxPriority && direction == 1) &&
      (await crud(state, [
        {
          db: "intake",
          collection: "disorders",
          parameters: [{ priority: disorder.priority + direction }, { $set: { priority: disorder.priority } }],
          method: "updateOne",
        },
        {
          db: "intake",
          collection: "disorders",
          parameters: [{ disId: disorder.disId }, { $set: { priority: disorder.priority + direction } }],
          method: "updateOne",
        },
      ]).then(() => {
        FUNC_initializeSymptomsAndDisorders(state);
      }));
  }

  function FUNC_queryQuestions(symptomCode, allQuestions, allDisorderQuestions, state, STATE_viewType) {
    var qq = [];
    STATE_setQueriedDisorderQuestions(allDisorderQuestions.filter((dq) => dq.symptom == symptomCode));
    let initialQ = allQuestions.filter((q) => q.pathways == "(" + symptomCode + ")=1");
    initialQ.map((iq, key) => {
      if (STATE_viewType == "central" || (STATE_viewType == "individual" && iq.approvals.find((doc) => doc.did == state.doctor.did))) {
        qq.push({ 0: [["0", key, iq]] });
        let followupQs = allQuestions.filter((q) => String(q.pathways).includes("{" + iq.code + "*"));
        followupQs.map((fq) => {
          let fqp = fq.pathways;
          let followupIndex = fqp.split("=")[fqp.split("=").length - 1];
          let newQuestionEntry = [
            fqp.split("*")[fqp.split("*").length - 2].split("{")[1],
            fqp.split("*")[fqp.split("*").length - 1].split("}")[0],
            fq,
          ];
          qq[qq.length - 1][followupIndex]
            ? qq[qq.length - 1][followupIndex].push(newQuestionEntry)
            : (qq[qq.length - 1][followupIndex] = [newQuestionEntry]);
        });
      }
    });
    STATE_setQueriedQuestions(qq);
    /*
    End data structure:
    [
      {
        0:[
          [0, “”, Q1]
            ]
        1:[
          [Q1.code, “Presented”, Q2], 
          [Q1.code, “Unpresented”, Q3]
                      ],
        2:[
          [Q2.code, “optionX”, Q4], 
          [Q3.code, “optionY”, Q5],
          [Q3.code, “optionX”, Q6]
          ],
        3:[
          [Q6.code, “Unpresented”, Q7]
          ],
      }
    ]
    */
  }

  function FUNC_toolSymptomsCaught(STATE_setSymptomsCaught, STATE_symptomModelVersion, patientInputRef) {
    STATE_setSymptomsCaught([]);
    setLoadedRef(false);
    let version = STATE_symptomModelVersion ? "new" : "old";
    let config = {
      method: "GET",
      url:
        "https://chatbotserver.brightlight.ai/toolSymptomsCaught" +
        "?doctorResponse=How are you doing?" +
        "&patientResponse=" +
        patientInputRef.current.value +
        "&version=" +
        version,
      headers: { "Content-Type": "application/json" },
    };
    axios(config)
      .then(function (response) {
        setLoadedRef(true);
        console.log("toolSymptomsCaught success: ");
        STATE_setSymptomsCaught([0]);
        for (let i = 0; i < STATE_allSymptoms.length; i++) {
          for (let j = 0; j < response.data.length; j++) {
            if (response.data[j] == parseInt(STATE_allSymptoms[i].Code)) {
              STATE_setSymptomsCaught((prevSC) => [...prevSC, STATE_allSymptoms[i]]);
            }
          }
        }
        Swal.fire({ title: "Model Response", html: response.data.replaceAll("\n", "<br/>") });
      })
      .catch(function (error) {
        console.log("toolSymptomsCaught error: ");
        console.log(error);
        console.log("retrying: ");
        FUNC_toolSymptomsCaught();
      });
  }

  function FUNC_deleteQuestion(q, chainId, state) {
    Swal.fire({
      title: "Delete Question?",
      text: "Deleting this question will delete all sub questions in this chain if there are any. You won't be able to revert this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it.",
    }).then((result) => {
      if (result.isConfirmed) {
        let questionPassed = false;
        let questionsToDelete = [];
        console.log(STATE_queriedQuestions);
        console.log(chainId);
        Object.keys(STATE_queriedQuestions[chainId]).forEach(function (key, index) {
          if (STATE_queriedQuestions[chainId][key][0][2].code == q.code || questionPassed == true) {
            questionPassed = true;
            questionsToDelete.push(STATE_queriedQuestions[chainId][key][0][2]);
          }
        });
        questionsToDelete.map(async (subQ) => {
          if (subQ.approvals.length > 1) {
            var filteredApprovals = subQ.approvals.filter(function (app) {
              return app.did != state.doctor.did;
            });
            await crud(state, [
              {
                db: "intake",
                collection: "symptom_questions",
                parameters: [{ id: subQ.id }, { $set: { approvals: filteredApprovals } }],
                method: "updateOne",
              },
            ]).then(() => {
              STATE_setQuestionsToUpdate(q);
            });
          } else {
            let qid = subQ.id;
            await crud(state, [
              {
                db: "intake",
                collection: "symptom_questions",
                parameters: [{ qid }],
                method: "deleteOne",
              },
            ]).then(() => {
              STATE_setQuestionsToUpdate(questionsToDelete);
            });
          }
        });
      }
    });
  }

  function FUNC_disapproveQuestion(q, state) {
    Swal.fire({
      title: "Un-Endorse Question?",
      text: "Un-endorsing this question will mean you removing it and all sub questions in this chain if there are any from your individual view. If you are the only doctor that has endorsed/created this it will permanently delete this question and all subchains.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, un-endorse it.",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (q.approvals.length > 1) {
          var filteredApprovals = q.approvals.filter(function (app) {
            return app.did != state.doctor.did;
          });
          await crud(state, [
            {
              db: "intake",
              collection: "symptom_questions",
              parameters: [{ id: q.id }, { $set: { approvals: filteredApprovals } }],
              method: "updateOne",
            },
          ]).then(() => {
            STATE_setQuestionsToUpdate(q);
          });
        } else {
          let qid = q.id;
          await crud(state, [
            {
              db: "intake",
              collection: "symptom_questions",
              parameters: [{ qid }],
              method: "deleteOne",
            },
          ]).then(() => {
            STATE_setQuestionsToUpdate(q);
          });
        }
      }
    });
  }

  function FUNC_approveQuestion(q, state) {
    Swal.fire({
      title: "Endorse Question?",
      text: "Endorsing this question will bring it as part of your own available question set and add your credential as a clinican validating this question to be asked under the specified context.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, endorse it.",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await crud(state, [
          {
            db: "intake",
            collection: "symptom_questions",
            parameters: [{ id: q.id }, { $set: { approvals: q.approvals.concat([{ did: state.doctor.did, photo: state.doctor.photo }]) } }],
            method: "updateOne",
          },
        ]).then(() => {
          STATE_setQuestionsToUpdate(q);
        });
      }
    });
  }

  return [
    FUNC_initializeSymptomsAndDisorders,
    FUNC_initializeQuestions,
    FUNC_queryQuestions,
    FUNC_toolSymptomsCaught,
    FUNC_deleteQuestion,
    FUNC_approveQuestion,
    FUNC_disapproveQuestion,
    FUNC_changeDisorderPriority,
    loadedRef,
    STATE_queriedQuestions,
    STATE_queriedDisorderQuestions,
    STATE_allQuestions,
    STATE_allDisorderQuestions,
    STATE_allSymptoms,
    STATE_allDisorders,
    STATE_selectedDisorder,
    STATE_setSelectedDisorder,
    STATE_selectedSymptom,
    STATE_setSelectedSymptom,
    STATE_questionsToUpdate,
  ];
}
