import { nanoid } from "nanoid";
import { promptAI } from "./promptAI";

export async function autogenerateSymptom(name, setName, setDescription, disorder, allSymptoms, setIsAutogenerating, toast) {
  setIsAutogenerating(true);
  const autogenerationToast = toast({
    title: "Symptom Autogenerating",
    description: "Please wait a few seconds for the symptom and it's corresponding description to generate.",
    status: "loading",
    variant: "subtle",
    duration: null,
    isClosable: true,
  });

  try {
    const autogeneratedSymptom = name
      ? { data: { output: name } }
      : await promptAI(
          "You are a symptom generator",
          "Generate a 1-5 word symptom that must be met for " +
            disorder.name +
            " to be diagnosed. An example is 'Persistent Negative Mood' for 'Major Depressive Disorder'. The following symptoms have already been added, so don't repeat any: " +
            (allSymptoms
              .filter((s) => s.associatedDisorders.includes(disorder.did))
              .map((s) => s.name)
              .join(", ") || "none") +
            "."
        );
    const autogeneratedDescription = await promptAI(
      "You are a description generator",
      "Generate an simple description of what this symptom entails: '" + autogeneratedSymptom?.data?.output + "'"
    );
    setName(autogeneratedSymptom.data.output.replace(/^['"]|['"]$/g, ""));
    setDescription(autogeneratedDescription.data.output);

    setIsAutogenerating(false);

    toast({
      title: "Symptom Autogenerated",
      description: "A symptom has been successfully autogenerated for this disorder.",
      status: "success",
      isClosable: true,
    });
  } catch (err) {
    setIsAutogenerating(false);
    toast({
      title: "Symptom Not Autogenerated",
      description: err?.message || "An error occurred.",
      status: "error",
      isClosable: true,
    });
  }

  toast.close(autogenerationToast);
}
