import { Table, Thead, Tbody, Tr, Th, Td, IconButton, Icon, Box, TableCaption, useToast } from "@chakra-ui/react";
import { formatDateTime } from "./helpers/timeFormatters";
import { DashStateContext } from "../../pages/Design";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import { updateSymptom } from "./helpers/updateSymptom";
import CreateSymptom from "./CreateSymptom";
import { renderEditableField } from "./helpers/renderEditableField";
import DeleteSymptom from "./DeleteSymptom";
import Questions from "./Questions";
import { useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";

export default function Symptoms({ allSymptoms, symptoms, setSymptoms, disorder }) {
  const toast = useToast();
  const [editStatus, setEditStatus] = useState({});
  const [currentEdit, setCurrentEdit] = useState({});
  const [symptom, setSymptom] = useState();

  return (
    <>
      <Tr>
        <Td colSpan="6" textAlign={"center"}>
          <Box overflowX="auto" p="4" boxShadow={"md"}>
            <Table size="sm" overflowX="scroll">
              <TableCaption>
                <Icon as={AiOutlineSetting} fontSize={"15px"} mt="-0.5" /> Click on a symptom to configure it
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Priority</Th>
                  <Th>Name</Th>
                  <Th>Description</Th>
                  <Th>Metadata</Th>
                  <Th>Created</Th>
                </Tr>
              </Thead>
              <Tbody overflowX="scroll">
                {symptoms
                  .sort((a, b) => a.priority - b.priority)
                  .map((symptom, symptomIndex) => (
                    <Tr key={symptomIndex}>
                      <Td w="10px" onClick={() => !editStatus[symptomIndex] && setSymptom(symptom)} cursor="pointer">
                        {renderEditableField(editStatus, currentEdit, setCurrentEdit, symptomIndex, "priority", symptom.priority, "number")}
                      </Td>
                      <Td w="24vh" onClick={() => !editStatus[symptomIndex] && setSymptom(symptom)} cursor="pointer">
                        {renderEditableField(editStatus, currentEdit, setCurrentEdit, symptomIndex, "name", symptom.name, "text")}
                      </Td>
                      <Td w="44vh" onClick={() => !editStatus[symptomIndex] && setSymptom(symptom)} cursor="pointer">
                        {renderEditableField(editStatus, currentEdit, setCurrentEdit, symptomIndex, "description", symptom.description, "text", 10)}
                      </Td>
                      <Td w="20vh" onClick={() => !editStatus[symptomIndex] && setSymptom(symptom)} cursor="pointer">
                        {renderEditableField(editStatus, currentEdit, setCurrentEdit, symptomIndex, "metadata", symptom.metadata, "text", 10)}
                      </Td>
                      <Td w="16vh" onClick={() => !editStatus[symptomIndex] && setSymptom(symptom)} cursor="pointer">
                        {formatDateTime(symptom.dateCreated, true)}
                      </Td>
                      <Td w="16vh">
                        {editStatus[symptomIndex] ? (
                          <IconButton
                            aria-label="Confirm edit"
                            icon={<CheckIcon />}
                            variant="outline"
                            size="sm"
                            m="1"
                            onClick={() => {
                              {
                                updateSymptom(setSymptoms, currentEdit[symptomIndex], toast);
                                setEditStatus({ ...editStatus, [symptomIndex]: false });
                              }
                            }}
                          />
                        ) : (
                          <IconButton
                            aria-label="Edit symptom"
                            icon={<EditIcon />}
                            variant="outline"
                            size="sm"
                            m="1"
                            onClick={() => {
                              {
                                setEditStatus({ ...editStatus, [symptomIndex]: true });
                                setCurrentEdit({
                                  ...currentEdit,
                                  [symptomIndex]: { ...symptom, dateCreated: formatDateTime(symptom.dateCreated, true) },
                                });
                              }
                            }}
                          />
                        )}
                        <DeleteSymptom setSymptoms={setSymptoms} symptom={symptom} did={disorder.did} />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <CreateSymptom allSymptoms={allSymptoms} setSymptoms={setSymptoms} disorder={disorder} />
          <Questions symptom={symptom} setSymptom={setSymptom} setSymptoms={setSymptoms} />
        </Td>
      </Tr>
    </>
  );
}
